import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Loader from '../utils/loader.jsx';

import { getUserCardsAPI } from '../../services/cards.js';

import { Header } from '../../components/navigation/header.jsx';
import { AccountDetailsSubHeader } from '../../components/navigation/accountDetailsSubHeader.jsx';
import { BottomMenu } from '../../components/navigation/bottomMenu.jsx';
import { CardItem } from '../../components/cards/cardItem.jsx';
import { TemplateCardItem } from '../../components/cards/templateCardItem.jsx';

export default function Cards() {
	const { t } = useTranslation();

	const langDir = localStorage.getItem('languageDir');

	const [selectedTab, setSelectedTab] = useState('active');

	const [cards, setCards] = useState([]);
	const [cardTemplates, setCardTemplates] = useState([]);

	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		const getUserCards = async () => {
			setIsLoading(true);
			const response = await getUserCardsAPI();
			if (response.isSuccess) {
				setCards(response.cards);
				setCardTemplates(response.templates);
				if (response.cards.length === 0) setSelectedTab('apply');
			}
			setIsLoading(false);
		};
		getUserCards();
	}, []);

	return isLoading ? (
		<Loader />
	) : (
		<div className="flex flex-col justify-between h-screen p-5" dir={langDir}>
			<div className="pb-[100px]">
				<Header title={t('pages.cards.header')} />
				<AccountDetailsSubHeader />

				<div className="flex text-md text-darkGray">
					<div className={`me-5 ${selectedTab === 'active' && 'font-bolder text-black'}`} onClick={() => setSelectedTab('active')}>
						{t('pages.cards.label.activeCards')}
						{selectedTab === 'active' && <div className="bg-black h-[2px] mt-[14px]"></div>}
					</div>

					<div
						className={`${selectedTab === 'apply' && 'font-bolder text-black'}`}
						onClick={async () => {
							if (cardTemplates.length === 0) return;
							setSelectedTab('apply');
						}}
					>
						{t('pages.cards.label.apply')}
						{selectedTab === 'apply' && <div className="bg-black h-[2px] mt-[14px]"></div>}
					</div>
				</div>

				{selectedTab === 'active' ? (
					<div>
						<div>
							<h1 className="text-lg text-gradient font-bolder mt-[30px]"> {t('pages.cards.label.virtualCards')}</h1>
							{cards.filter((card) => card.cardTemplate.type === 'virtual').length === 0 ? (
								<div className="mt-[10px] mb-[40px]">
									<p className="text-lg font-bolder text-center mb-[10px]"> {t('pages.cards.label.virtual.noCards')}</p>
								</div>
							) : (
								cards
									.filter((card) => card.cardTemplate.type === 'virtual')
									.map((card) => (
										<div key={card._id}>
											<CardItem card={card} key={card._id} />
										</div>
									))
							)}
						</div>
						<div>
							<h1 className="text-lg text-gradient font-bolder mt-[20px]"> {t('pages.cards.label.physicalCards')}</h1>
							{cards.filter((card) => card.cardTemplate.type === 'physical').length === 0 ? (
								<div className="mt-[10px] mb-[40px]">
									<p className="text-lg font-bolder text-center mb-[10px]"> {t('pages.cards.label.physical.noCards')}</p>
								</div>
							) : (
								cards
									.filter((card) => card.cardTemplate.type === 'physical')
									.map((card) => (
										<div key={card._id}>
											<CardItem card={card} key={card._id} />
										</div>
									))
							)}
						</div>
					</div>
				) : (
					<div>
						<div>
							<h1 className="text-lg text-gradient font-bolder mt-[30px]"> {t('pages.cards.label.virtual.orderNew')}</h1>
							{cardTemplates.filter((template) => template.type === 'virtual').length === 0 ? (
								<div className="mt-[10px] mb-[40px]">
									<p className="text-lg font-bolder text-center mb-[10px]"> {t('pages.cards.label.virtual.aquired')}</p>
								</div>
							) : (
								cardTemplates
									.filter((template) => template.type === 'virtual')
									.map((template) => (
										<div key={template._id}>
											<TemplateCardItem template={template} key={template._id} />
										</div>
									))
							)}
						</div>
						<div>
							<h1 className="text-lg text-gradient font-bolder mt-[20px]"> {t('pages.cards.label.physical.orderNew')}</h1>
							{cardTemplates.filter((template) => template.type === 'physical').length === 0 ? (
								<div className="mt-[10px] mb-[40px]">
									<p className="text-lg font-bolder text-center mb-[10px]"> {t('pages.cards.label.physical.aquired')}</p>
								</div>
							) : (
								cardTemplates
									.filter((template) => template.type === 'physical')
									.map((template) => (
										<div key={template._id}>
											<TemplateCardItem template={template} key={template._id} />
										</div>
									))
							)}
						</div>
					</div>
				)}
			</div>
			<BottomMenu selected="cards" />
		</div>
	);
}
