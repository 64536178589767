import { useNavigate } from 'react-router-dom';

import IntercomNonUserChat from '../chats/intercomNonUserChat';
import IntercomUserChat from '../chats/intercomUserChat';
import { LanguageSwitcher } from '../utils/languageSwitcher';

export const Header = ({
	title,
	showUserChat = true,
	showNonUserChat = false,
	hasBack = true,
	hasIcon = false,
	backNavigation = null,
	showLanguage = true,
	isChatColorBlack = true,
}) => {
	const navigate = useNavigate();

	const langDir = localStorage.getItem('languageDir');

	return (
		<div dir={langDir}>
			<div className="h-[60px] text-black font-bold flex justify-between items-center mb-2">
				{hasBack && (
					<img
						src={`${langDir === 'rtl' ? '/assets/icons/right_arrow_black.svg' : '/assets/icons/left_arrow.svg'}`}
						className={`${langDir === 'rtl' ? 'h-[18px]' : 'h-[27px]'}`}
						onClick={backNavigation ? backNavigation : () => navigate(-1)}
					/>
				)}
				{hasIcon && <img src="/assets/icons/rainbow_icon.svg" className="w-[24px]" onClick={() => navigate('/')} />}
				<p>{title}</p>
				<div className="flex justify-center items-center">
					{showNonUserChat && <IntercomNonUserChat logoBlack={isChatColorBlack} />}
					{showUserChat && <IntercomUserChat logoBlack={isChatColorBlack} />}
					{/* {showLanguage && <LanguageSwitcher logoBlack={isChatColorBlack} />} */}
				</div>
			</div>
		</div>
	);
};
