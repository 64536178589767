import { useState } from 'react';

import useValidation from '../../hooks/useValidation';

import { updateUserPassword } from '../../services/user';

import { PrimaryButton } from '../../components/buttons/primary-button';

import { Header } from '../../components/navigation/header';

import { ErrorBubble } from '../../components/utils/errorBubble';
import PasswordInput from '../../components/inputs/passwordInput';
import { InstructionBubble } from '../../components/utils/InstructionBubble';

import { ChangePasswordSchema } from '../../utils/validations/user/user_schema';
import { useTranslation } from 'react-i18next';
import { logout } from '../../services/auth';
import { useNavigate } from 'react-router-dom';
import { PincodeModal } from '../../components/modals/pincodeModal';
import Loader from '../utils/loader';

export default function ChangePassword() {
	const { t } = useTranslation();
	const langDir = localStorage.getItem('languageDir');

	const navigate = useNavigate();

	const [currentPassword, setCurrentPassword] = useState('');
	const [newPassword, setNewPassword] = useState('');
	const [confirmNewPassword, setConfirmNewPassword] = useState('');

	const { validationErrMsg, validate } = useValidation(ChangePasswordSchema);

	const [apiErrMsg, setApiErrMsg] = useState('');
	const [isLoading, setIsLoading] = useState(false);

	const [isPincodeModalOpen, setIsPincodeModalOpen] = useState(false);

	const updatePassword = async (pincode) => {
		if (!isLoading) {
			setIsLoading(true);
			const response = await updateUserPassword({ oldPassword: currentPassword, newPassword, pincode });
			if (!response.isSuccess) {
				setIsLoading(false);
				setIsPincodeModalOpen(false);
				return setApiErrMsg(response.error);
			}
			setIsPincodeModalOpen(false);
			navigate('/success');
			logout(false);
		}
		setIsLoading(false);
	};

	return isLoading ? (
		<Loader />
	) : (
		<>
			<form
				onSubmit={(e) => {
					e.preventDefault();
					const data = {
						currentPassword,
						newPassword,
						confirmNewPassword,
					};

					const isValid = validate(data);
					if (!isValid) return;
					setIsPincodeModalOpen(true);
				}}
				className="flex flex-col justify-between p-5 h-screen"
			>
				<div>
					<Header title={t('pages.profile.updatePassword.header')} />
					<InstructionBubble title={t('passwordInstructions.updateTitle')} text={t('passwordInstructions.message')} className="mt-[40px] mb-[20px]" />

					<p dir={langDir} className="font-bold mt-2 mb-1">
						{t('pages.profile.updatePassword.label.currentPassword')}
					</p>

					<PasswordInput value={currentPassword} onChange={setCurrentPassword} placeholder={t('inputs.placeholders.currentPassword')} />

					<p dir={langDir} className="font-bold mt-2 mb-1">
						{t('pages.profile.updatePassword.label.setNewPassword')}
					</p>

					<PasswordInput value={newPassword} onChange={setNewPassword} placeholder={t('inputs.placeholders.newPassword')} />
					<PasswordInput value={confirmNewPassword} onChange={setConfirmNewPassword} placeholder={t('inputs.placeholders.confirmNewPassword')} />
				</div>
				<div>
					{validationErrMsg && <ErrorBubble error={validationErrMsg} />}
					{apiErrMsg && <ErrorBubble error={apiErrMsg} />}
					<PrimaryButton text={t('buttons.updatePassword')} textColor="text-white" />
				</div>
			</form>
			<PincodeModal
				isOpen={isPincodeModalOpen}
				onClose={() => {
					setIsPincodeModalOpen(false);
				}}
				onAction={async (pincode) => await updatePassword(pincode)}
			/>
		</>
	);
}
