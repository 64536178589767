import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import NotFound404 from '../../utils/404';

import Loader from '../../utils/loader';
import { TranferSchema } from '../../../utils/validations/user-schema';

import useValidation from '../../../hooks/useValidation';

import { getFeeByType } from '../../../services/fees';
import { getTransferReceiverByCustomerNumberAPI, transferToUserAPI } from '../../../services/transactions';

import { Header } from '../../../components/navigation/header';
import SelectInput from '../../../components/inputs/selectInput';
import { Input } from '../../../components/inputs/textInput';
import { AccountDetailsSubHeader } from '../../../components/navigation/accountDetailsSubHeader';
import { ErrorBubble } from '../../../components/utils/errorBubble';
import { PrimaryButton } from '../../../components/buttons/primary-button';
import { PageLogo } from '../../../components/utils/pageLogo';
import { useTranslation } from 'react-i18next';
import { getUserStatuses } from '../../../services/user';

export default function PaytoraMemberTransfer() {
	const { t } = useTranslation();

	const langDir = localStorage.getItem('languageDir');

	const navigate = useNavigate();

	const { userWallets } = useSelector((state) => state.accounts);

	const { currencyCode } = useParams();

	const [currency, setCurrency] = useState(userWallets[0].currency);

	const [amount, setAmount] = useState('');
	const [customerNumber, setCustomerNumber] = useState('');
	const [customerName, setCustomerName] = useState('');
	const [reason, setReason] = useState('');
	const [errorMsg, setErrorMsg] = useState('');

	const [accountError, setAccountError] = useState('');

	const [isLoading, setIsLoading] = useState(false);

	const [totalFee, setTotalFee] = useState(0);

	const [isMerchant, setIsMerchant] = useState(false);

	const { validationErrMsg, validate } = useValidation(TranferSchema(t));

	const transfer = async () => {
		const data = {
			customerNumber,
			customerName,
			currency: currencyCode ?? currency,
			amount: parseFloat(amount),
			reason,
		};

		if (!amount || amount < 0) return setErrorMsg(t('errors.validations.invalidAmount'));

		const isValid = validate(data);
		if (!isValid) return;

		setErrorMsg('');

		if (!isLoading) {
			setIsLoading(true);

			const response = await transferToUserAPI(data);

			if (!response.isSuccess) {
				setIsLoading(false);
				setErrorMsg(response.error ?? 'Please make sure you have enough balance & wallet member id is correct!');
				setReason('');
				return;
			}

			setIsLoading(false);
			navigate('/success');
		}
	};

	const fetchReceiverByCustomerNumber = async (receiverCnumber) => {
		const response = await getTransferReceiverByCustomerNumberAPI(receiverCnumber);
		if (response.isSuccess) {
			setCustomerName(response.data);
			setAccountError('');
		} else setAccountError(t('errors.validations.accountNotFound'));
	};

	useEffect(() => {
		const fetchData = async () => {
			const feesResponse = await getFeeByType({ type: 'transfer' });

			if (feesResponse.isSuccess) feesResponse.data.forEach((fee) => setTotalFee(fee.cost));
		};

		const userStatuses = async () => {
			if (!isLoading) {
				const response = await getUserStatuses();
				setIsMerchant(response.data.userType === 'merchant');
			}
		};
		userStatuses();
		fetchData();
	}, []);

	if (currencyCode && currencyCode !== 'USD' && currencyCode !== 'EUR') return <NotFound404 />;

	return isLoading ? (
		<Loader />
	) : (
		<div className="p-5" dir={langDir}>
			<div className="h-screen flex flex-col justify-between">
				<div>
					<Header title={t('pages.transfer.header')} />
					<AccountDetailsSubHeader showBalance={false} showAmount={true} amount={amount > 0 ? amount - totalFee : 0} />

					<h1 className="font-bolder text-lg mb-[10px]">{t('pages.transfer.title')}</h1>
					<div>
						<label className="text-darkGray">{t('pages.transfer.label.currency')}</label>
						<div dir="ltr">
							<SelectInput
								defaultValue={{ value: currencyCode ?? userWallets[0].currency, label: currencyCode ?? userWallets[0].currency }}
								onChange={(value) => {
									if (!currencyCode) setCurrency(value);
								}}
								options={
									currencyCode
										? []
										: userWallets.filter((wallet) => wallet.currency !== 'GBP').map((wallet) => ({ value: wallet.currency, label: wallet.currency }))
								}
							/>
						</div>

						<p className="text-darkGray mb-2">
							<b>{t('pages.transfer.label.availableAmount')} </b>
							{(() => {
								const wallet = userWallets.find((wallet) => wallet.currency === (currencyCode ?? currency));
								return wallet ? `${wallet.balance}${wallet.symbol}` : '0.00';
							})()}
						</p>

						<label className="text-darkGray">{t('pages.transfer.label.amount')}</label>

						<Input
							placeholder={t('inputs.placeholders.transferAmount')}
							type="number"
							value={amount}
							onChange={(value) => {
								if (value.length > 10) return;
								setAmount(value);
							}}
							inputMode="numeric"
							pattern="[0-9]"
						/>

						<label className="text-darkGray">{t('pages.transfer.label.customerNumber')}</label>
						<Input
							onChange={async (value) => {
								if (value.length > 6) return;
								setCustomerNumber(value);
								if (value.length > 5) await fetchReceiverByCustomerNumber(value);
								else setCustomerName('');
							}}
							type="text"
							value={customerNumber}
						/>
						{accountError && <p className="text-red mb-2">{accountError}</p>}
						<label className="text-darkGray">{t('pages.transfer.label.customerName')}</label>
						<Input disabled={true} type="text" value={customerName} />
						<label className="text-darkGray">{t('pages.transfer.label.reason')}</label>
						<SelectInput
							onChange={setReason}
							options={[
								{ label: t('pages.transfer.reasons.familySupport'), value: 'Family Support' },
								{ label: t('pages.transfer.reasons.gift'), value: 'Gift' },
								{ label: t('pages.transfer.reasons.loan'), value: 'Loan' },
								...(isMerchant
									? [
											{ label: t('pages.transfer.reasons.ftd'), value: 'FTD Bonus' },
											{ label: t('pages.transfer.reasons.referral'), value: 'Refferal Bonus' },
											{ label: t('pages.transfer.reasons.refund'), value: 'Refund' },
									  ]
									: []),
							]}
							defaultValue={{ label: t('pages.transfer.reasons.default'), value: '' }}
						/>
					</div>
					{totalFee > 0 && amount > 0 && <p className="text-red">{`${t('pages.transfer.label.fees')} €${totalFee} `}</p>}
					<div className="flex justify-center">
						<PageLogo />
					</div>
				</div>

				<div>
					{errorMsg && <ErrorBubble error={errorMsg} />}
					{validationErrMsg && <ErrorBubble error={validationErrMsg} />}
					<PrimaryButton iconPath="/icons/arrow_out_white.svg" text={t('buttons.send')} textColor="text-white" onClick={transfer} />
				</div>
			</div>
		</div>
	);
}
