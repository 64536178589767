import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Header } from '../../../components/navigation/header';
import SelectInput from '../../../components/inputs/selectInput';
import { PrimaryButton } from '../../../components/buttons/primary-button';
import { ErrorBubble } from '../../../components/utils/errorBubble';

import Loader from '../../utils/loader';

import { otherTopUpAccountAPI } from '../../../services/transactions';

import { PageLogo } from '../../../components/utils/pageLogo';

import { PincodeModal } from '../../../components/modals/pincodeModal';
import { AccountDetailsSubHeader } from '../../../components/navigation/accountDetailsSubHeader';
import { Input } from '../../../components/inputs/textInput';

export default function CryptoExchangePage() {
	const navigate = useNavigate();

	const { t } = useTranslation();

	const langDir = localStorage.getItem('languageDir');

	const [currency, setCurrency] = useState('USD');

	const [isLoading, setIsLoading] = useState(false);

	const [errMsg, setErrMsg] = useState('');

	const [amount, setAmount] = useState(null);

	const [isPincodeModalOpen, setIsPincodeModalOpen] = useState(false);

	const depositHandler = async (pincode) => {
		if (!amount || amount <= 0) return setErrMsg(t('errors.validations.invalidAmount'));

		if (!isLoading) {
			setIsLoading(true);

			const response = await otherTopUpAccountAPI({ amount, currency, pincode });
			if (!response.isSuccess) {
				setErrMsg(response.error ?? t('errors.server.GENERAL_ERROR'));
			} else {
				setErrMsg('');
				window.open(response.data, '_self');
			}
			return setIsLoading(false);
		}
	};

	return isLoading ? (
		<Loader />
	) : (
		<div className="flex flex-col justify-between p-5 h-screen" dir={langDir}>
			<div>
				<Header title={t('pages.deposit.header')} />

				<AccountDetailsSubHeader showBalance={true} />

				<p className="text-lg mb-[10px]">{t('pages.deposit.title')}</p>
				<p className="text-darkGray">{t('pages.deposit.label.selectToMethod')}</p>

				<SelectInput
					defaultValue={{
						value: 'USD',
						label: 'USD',
					}}
					onChange={(value) => {
						setCurrency(value);
					}}
					options={[
						{ label: 'USD', value: 'USD' },
						{ label: 'EUR', value: 'EUR' },
					]}
				/>

				<Input placeholder="0.00" type="number" value={amount} onChange={(value) => setAmount(value)} inputMode="numeric" pattern="[0-9]" />
			</div>

			<PageLogo />

			<div>
				{errMsg && <ErrorBubble error={errMsg} />}
				<PrimaryButton
					text={t('buttons.makeDeposit')}
					textColor="text-white"
					iconPath="icons/arrow_in_white.svg"
					onClick={() => {
						setIsPincodeModalOpen(true);
					}}
					className="mb-5"
				/>
			</div>

			<PincodeModal
				isOpen={isPincodeModalOpen}
				onClose={() => {
					setIsPincodeModalOpen(false);
					navigate(-1);
				}}
				onAction={(pincode) => {
					depositHandler(pincode);
					setIsPincodeModalOpen(false);
				}}
			/>
		</div>
	);
}
