import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Loader from '../utils/loader';

import { CountriesListWithPrefix } from '../../utils/constants/countries-list';

import { getUserProfileAPI, updateUserAddressAPI } from '../../services/user';

import { Header } from '../../components/navigation/header';
import { Input } from '../../components/inputs/textInput';
import { PrimaryButton } from '../../components/buttons/primary-button';
import SelectInput from '../../components/inputs/selectInput';
import { PageLogo } from '../../components/utils/pageLogo';
import { ErrorBubble } from '../../components/utils/errorBubble';
import { CustomModal } from '../../components/modals/customModal';
import { PincodeModal } from '../../components/modals/pincodeModal';

export default function EditProfileAddress() {
	const navigate = useNavigate();

	const { t } = useTranslation();

	const langDir = localStorage.getItem('languageDir');

	const [isLoading, setIsLoading] = useState(false);

	const [addressData, setAddressData] = useState(null);
	const [errMsg, setErrMsg] = useState('');

	const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
	const [isPincodeModalOpen, setIsPincodeModalOpen] = useState(false);

	useEffect(() => {
		const getUserProfile = async () => {
			setIsLoading(true);
			const response = await getUserProfileAPI();
			if (response.isSuccess) {
				setAddressData(response.data);
			} else setErrMsg(t('errors.server.LOAD_PROFILE'));
			setIsLoading(false);
		};

		getUserProfile();
	}, [t]);

	const updateAddress = async (pincode) => {
		if (!isLoading) {
			setIsLoading(true);
			const { user, number, documentExpiryDate, verestroVerificationId, ...filteredAdress } = addressData;
			const response = await updateUserAddressAPI({ ...filteredAdress, pincode });
			if (!response.isSuccess) {
				setIsLoading(false);
				setIsPincodeModalOpen(false);
				return setErrMsg(response.error);
			}
		}
		setIsLoading(false);
		setIsPincodeModalOpen(false);
		setIsSuccessModalOpen(true);
	};

	return isLoading ? (
		<Loader />
	) : (
		<div className="p-5 flex flex-col justify-between h-screen">
			{addressData ? (
				<div>
					<Header title={t('pages.profile.editAddress.header')} />
					<div dir={langDir}>
						<h1 className="text-xl font-bolder mt-[30px] mb-[10px]">{t('pages.profile.editAddress.title')}</h1>
						<p className="text-darkGray">{t('pages.profile.editAddress.subTitle')}</p>
						<p className="text-sm mt-[25px] text-end">{t('pages.profile.editAddress.requiredWarning')}</p>

						<p className="text-darkGray">{t('pages.profile.editAddress.label.street')}</p>
						<Input
							onChange={(value) =>
								setAddressData((data) => ({
									...data,
									street: value,
								}))
							}
							type="text"
							value={addressData.street}
						/>
						<p className="text-darkGray">{t('pages.profile.editAddress.label.city')}</p>
						<Input
							onChange={(value) =>
								setAddressData((data) => ({
									...data,
									city: value,
								}))
							}
							type="text"
							value={addressData.city}
						/>
						<p className="text-darkGray">{t('pages.profile.editAddress.label.apartment')}</p>
						<Input
							onChange={(value) =>
								setAddressData((data) => ({
									...data,
									apartment: value,
								}))
							}
							type="text"
							value={addressData.apartment}
						/>
						<p className="text-darkGray">{t('pages.profile.editAddress.label.postalCode')}</p>
						<Input
							onChange={(value) =>
								setAddressData((data) => ({
									...data,
									postalCode: value,
								}))
							}
							type="text"
							value={addressData.postalCode}
						/>
						<p className="text-darkGray">{t('pages.profile.editAddress.label.country')}</p>

						<SelectInput
							defaultValue={{
								label: CountriesListWithPrefix.find((country) => country.code === addressData.country).country,
								value: CountriesListWithPrefix.find((country) => country.code === addressData.country).code,
							}}
							onChange={(value) => {
								setAddressData((data) => ({
									...data,
									country: value,
								}));
							}}
							options={CountriesListWithPrefix.map((opt) => ({
								value: opt.code,
								label: opt.country,
							}))}
						/>
					</div>
				</div>
			) : (
				<div></div>
			)}
			<div className="flex flex-col justify-center">
				<PageLogo />
				{errMsg && <ErrorBubble error={errMsg} />}
				<PrimaryButton text={t('buttons.updateAddress')} onClick={() => setIsPincodeModalOpen(true)} />
			</div>

			<CustomModal
				isOpen={isSuccessModalOpen}
				title={t('modals.editAddress.success.title')}
				subTitle={t('modals.editAddress.success.subTitle')}
				children={
					<div>
						<p className="text-md text-center mb-5">{t('modals.editAddress.success.message')}</p>
						<PrimaryButton
							text={t('buttons.close')}
							onClick={() => {
								setIsSuccessModalOpen(false);
								navigate('/');
							}}
							textColor="text-white"
						/>
					</div>
				}
			/>

			<PincodeModal
				isOpen={isPincodeModalOpen}
				onClose={() => {
					setIsPincodeModalOpen(false);
				}}
				onAction={async (pincode) => await updateAddress(pincode)}
			/>
		</div>
	);
}
