import { apiRequest } from './api';

export const getSumsubKYCTokenAPI = async ({ step }) => {
	try {
		const response = await apiRequest('/user/kyc', 'POST', { step });

		return { isSuccess: true, token: response.token };
	} catch (error) {
		return { isSuccess: false, error: error.message };
	}
};

export const getUserKYCStatusAPI = async () => {
	try {
		const response = await apiRequest('/user/kyc/status', 'GET');

		return { isSuccess: true, data: response.status };
	} catch (error) {
		return { isSuccess: false, error: error.message };
	}
};
