export const SelectButton = ({ onClick, text, iconPath = 'welcome/homeIcon.svg', isSelected = false }) => {
	return (
		<div className={`${isSelected ? 'border-gradient' : ''} w-full mt-2`}>
			<div className="bg-black rounded-[6px] h-[50px] flex items-center p-5">
				<button type="button" onClick={onClick} className={`w-full`}>
					<div className="flex justify-between">
						{iconPath ? <img src={`/assets/${iconPath}`} className="w-[30px]" /> : ''}
						<p className="text-white me-5">{text}</p>
					</div>
				</button>
			</div>
		</div>
	);
};
