import { useState } from 'react';
import { Header } from '../../components/navigation/header';

import { FaChevronDown } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

export default function FAQ() {
	const { t } = useTranslation();
	const langDir = localStorage.getItem('languageDir');

	const langPath = !langDir ? 'en' : langDir === 'ltr' ? 'en' : 'he';

	const faqs = [
		{
			question: t('pages.faq.questions.ibanOutsideEU'),
			answer: (
				<div>
					<p>{t('pages.faq.answers.ibanOutsideEU.title')}</p>
					<h1 className="text-xl mt-2">{t('pages.faq.answers.ibanOutsideEU.subTitle')}</h1>

					<p className="mt-2 mb-2">
						1. {t('pages.faq.answers.ibanOutsideEU.a1')} <img src={`/assets/guides/card_iban/${langPath}/bottom_menu_cards.png`} alt="card_guide" />
					</p>
					<hr />
					<p className="mt-2 mb-2">
						2. {t('pages.faq.answers.ibanOutsideEU.a2')} <img src={`/assets/guides/card_iban/${langPath}/apply_card.png`} alt="card_guide" />
					</p>
					<hr />
					<p className="mt-2 mb-2">
						3. {t('pages.faq.answers.ibanOutsideEU.a3')}
						<img src={`/assets/guides/card_iban/${langPath}/virtual_card_apply.png`} alt="card_guide" />
					</p>
					<hr />
					<p className="mt-2 mb-2">
						4. {t('pages.faq.answers.ibanOutsideEU.a4')}
						<img src={`/assets/guides/card_iban/${langPath}/active_virtual_card_deposit.png`} alt="card_guide" />
					</p>
					<hr />
					<p className="mt-2 mb-2">
						5. {t('pages.faq.answers.ibanOutsideEU.a5')}
						<img src={`/assets/guides/card_iban/${langPath}/pincode.png`} alt="card_guide" />
					</p>
					<hr />
					<p className="mt-2 mb-2">
						6. {t('pages.faq.answers.ibanOutsideEU.a6')}
						<img src={`/assets/guides/card_iban/${langPath}/card_wire_option.png`} alt="card_guide" />
						<img src={`/assets/guides/card_iban/${langPath}/deposit_button.png`} alt="card_guide" />
					</p>
					<hr />
					<p className="mt-2 mb-2">
						7. {t('pages.faq.answers.ibanOutsideEU.a7')}
						<img src={`/assets/guides/card_iban/${langPath}/card_iban_details.png`} alt="card_guide" />
					</p>
				</div>
			),
		},
	];

	const [openIndex, setOpenIndex] = useState(null);

	const toggleFAQ = (index) => {
		setOpenIndex(openIndex === index ? null : index);
	};

	return (
		<div className="p-5" dir={langDir}>
			<div>
				<Header title="FAQ" />
				<h1 className="text-lg font-bold text-center mb-5">{t('pages.faq.title')}</h1>
				{faqs.map((faq, index) => (
					<div key={index} className="mb-4 border rounded-lg shadow-md">
						<div
							onClick={() => toggleFAQ(index)}
							className="cursor-pointer bg-gray-100 p-5 flex justify-between items-center rounded-t-lg hover:bg-gray-200 transition-colors"
						>
							<strong className="text-lg text-gradient">{faq.question}</strong>
							<FaChevronDown className={`transform transition-transform ${openIndex === index ? 'rotate-180' : ''} text-gray-600`} />
						</div>
						{openIndex === index && (
							<div className="p-5 bg-white border-t border-gray-200 rounded-b-lg shadow-inner">
								<p className="text-gray-700">{faq.answer}</p>
							</div>
						)}
					</div>
				))}
			</div>
		</div>
	);
}
