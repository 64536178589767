import { apiRequest } from './api';

export async function getDashboardInformation() {
	try {
		const response = await apiRequest('/user/dashboard', 'GET');
		return { isSuccess: true, data: response.data };
	} catch (error) {
		return { isSuccess: false, error: error.message };
	}
}

export async function getVerestroUserExists() {
	try {
		const response = await apiRequest('/user/ver/status', 'GET');
		return { isSuccess: true, data: response.data };
	} catch (error) {
		return { isSuccess: false, error: error.message };
	}
}

export async function updateUserPassword(data) {
	try {
		await apiRequest('/user/profile/password', 'PATCH', data);
		return { isSuccess: true };
	} catch (error) {
		return { isSuccess: false, error: error.message };
	}
}

export async function getUserTransactionsAPI(kind, cardId, currency) {
	try {
		const response = await apiRequest('/user/transactions/types', 'POST', { kind, cardId, currency });
		return { isSuccess: true, data: response.data };
	} catch (error) {
		return { isSuccess: false, error: error.message };
	}
}

export async function getUserProfileAPI() {
	try {
		const response = await apiRequest('/user/profile', 'GET');
		return { isSuccess: true, data: response.data };
	} catch (error) {
		return { isSuccess: false, error: error.message };
	}
}

export async function updateUserAddressAPI(data) {
	try {
		await apiRequest('/user/profile/address', 'PATCH', data);
		return { isSuccess: true };
	} catch (error) {
		return { isSuccess: false, error: error.message };
	}
}

export async function getUserStatuses() {
	try {
		const response = await apiRequest('/user/statuses', 'GET');
		return { isSuccess: true, data: response.data };
	} catch (error) {
		return { isSuccess: false, error: error.message };
	}
}
