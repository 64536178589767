import { useLocation, useNavigate } from 'react-router-dom';

import NotFound404 from '../../utils/404';

import { Header } from '../../../components/navigation/header';
import { AccountDetailsSubHeader } from '../../../components/navigation/accountDetailsSubHeader';
import { GradientButton } from '../../../components/buttons/gradient_button';
import { PageLogo } from '../../../components/utils/pageLogo';
import { useTranslation } from 'react-i18next';
import { useIbanStatus } from '../../../hooks/useIbanStatus';
import Loader from '../../utils/loader';

export default function SendPage() {
	const navigate = useNavigate();

	const { t } = useTranslation();

	const langDir = localStorage.getItem('languageDir');

	const location = useLocation();
	const { currency, isIban } = location.state || {};

	const { isLoading, hasIban } = useIbanStatus();

	if (currency && currency !== 'USD' && currency !== 'EUR') return <NotFound404 />;

	return isLoading ? (
		<Loader />
	) : (
		<div className="flex flex-col justify-between p-5 h-screen" dir={langDir}>
			<div>
				<Header title={t('pages.send.header')} />
				<AccountDetailsSubHeader />
				<p className="text-lg font-bolder mb-[10px]">{t('pages.send.title')}</p>
				{!isIban && <GradientButton text={t('buttons.toPaytoraMember')} onClick={() => navigate(`/send/paytora-member/${currency ?? ''}`)} />}
				{!currency && hasIban === true && (
					<GradientButton text={t('buttons.fromMyIBANAccount')} onClick={() => navigate('/send/withdrawal', { state: { type: 'iban' } })} />
				)}
				{!isIban && (
					<GradientButton text={t('buttons.fromMyPaytoraAccount')} onClick={() => navigate('/send/withdrawal', { state: { type: 'paytora' } })} />
				)}
			</div>
			<PageLogo className="mb-20" />
		</div>
	);
}
