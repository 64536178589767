import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import numeral from 'numeral';

import { Header } from '../../../components/navigation/header';
import SelectInput from '../../../components/inputs/selectInput';
import { Input } from '../../../components/inputs/textInput';
import { PrimaryButton } from '../../../components/buttons/primary-button';
import { SelectButton } from '../../../components/buttons/selectButton';
import { ErrorBubble } from '../../../components/utils/errorBubble';
import { PageLogo } from '../../../components/utils/pageLogo';
import { CustomModal } from '../../../components/modals/customModal';
import { PincodeModal } from '../../../components/modals/pincodeModal';
import { AccountDetailsSubHeader } from '../../../components/navigation/accountDetailsSubHeader';

import Loader from '../../utils/loader';
import NotFound404 from '../../utils/404';

import { cardTopUpAccountAPI, otherTopUpAccountAPI } from '../../../services/transactions';
import { getUserCardsAPI } from '../../../services/cards';
import { getFeeByType } from '../../../services/fees';

import { useIbanStatus } from '../../../hooks/useIbanStatus';

export default function DepositPage() {
	const navigate = useNavigate();

	const { t } = useTranslation();

	const langDir = localStorage.getItem('languageDir');

	const location = useLocation();
	const { currency, isIban } = location.state || {};

	const { isLoading: ibanIsLoading, hasIban } = useIbanStatus();

	const [userCards, setUserCards] = useState([]);

	const toMethods = useMemo(() => {
		return [
			{
				label: t('pages.deposit.method.to.paytora.usd'),
				value: 'paytora_usd',
			},
			{
				label: t('pages.deposit.method.to.paytora.eur'),
				value: 'paytora_eur',
			},
			// ...(userCards.length > 0 ? [{ label: t('pages.deposit.method.card'), value: 'card' }] : []),
			...(hasIban ? [{ label: t('pages.deposit.method.to.iban'), value: 'iban' }] : []), // Add IBAN only if hasIban is true
		];
	}, [hasIban]);

	const fromMethods = [
		{
			method: 'paytora_eur',
			options: [
				...(userCards.length > 0 ? [{ label: t('pages.deposit.method.from.card'), value: 'card' }] : []),
				...(hasIban ? [{ label: t('pages.deposit.method.from.iban'), value: 'iban' }] : []),
				{ label: t('pages.deposit.method.from.other.card'), value: 'other' },
				{ label: t('pages.deposit.method.from.other.crypto'), value: 'others' },
			],
		},
		{
			method: 'paytora_usd',
			options: [
				{ label: t('pages.deposit.method.from.other.card'), value: 'other' },
				{ label: t('pages.deposit.method.from.other.crypto'), value: 'others' },
			],
		},
		{
			method: 'iban',
			options: [
				...(userCards.length > 0 ? [{ label: t('pages.deposit.method.from.card'), value: 'card' }] : []),
				{ label: t('pages.deposit.method.from.paytora.eur'), value: 'paytora_eur' },
				{ label: t('pages.deposit.method.from.wire'), value: 'wire' },
			],
		},
		{
			method: 'card',
			options: [
				{ label: t('pages.deposit.method.from.paytora.eur'), value: 'paytora_eur' },
				...(hasIban ? [{ label: t('pages.deposit.method.from.iban'), value: 'iban' }] : []),
				{ label: t('pages.deposit.method.from.wire'), value: 'card_wire' },
			],
		},
	];

	const [selectedToMethod, setSelectedToMethod] = useState(isIban ? 'iban' : currency === 'EUR' ? 'paytora_eur' : 'paytora_usd');
	const [selectedFromMethod, setSelectedFromMethod] = useState(null);

	const [selectedCard, setSelectedCard] = useState(null);

	const [isLoading, setIsLoading] = useState(false);

	const [errMsg, setErrMsg] = useState('');

	const [amount, setAmount] = useState();

	const [totalFee, setTotalFee] = useState(0);
	const [pTotalFee, setPtotalFee] = useState(0);

	const [isPincodeModalOpen, setIsPincodeModalOpen] = useState(false);

	const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);

	const depositHandler = async (pincode) => {
		if (!selectedFromMethod) return setErrMsg(t('errors.validations.paymentMethod'));

		if ((!amount || amount <= 0) && selectedFromMethod !== 'wire' && selectedFromMethod !== 'card_wire')
			return setErrMsg(t('errors.validations.invalidAmount'));

		if (!isLoading) {
			setIsLoading(true);
			if (selectedFromMethod === 'other' || selectedFromMethod === 'others') {
				const currency = selectedToMethod === 'paytora_usd' ? 'USD' : selectedToMethod === 'paytora_eur' ? 'EUR' : null;
				const response = await otherTopUpAccountAPI({ amount, currency, pincode });
				if (!response.isSuccess) {
					setErrMsg(response.error ?? t('errors.server.GENERAL_ERROR'));
				} else {
					setErrMsg('');
					window.open(response.data, '_self');
				}
				return setIsLoading(false);
			}
			if (selectedFromMethod === 'wire') {
				setIsLoading(false);
				return navigate('/iban/details');
			}
			if (selectedFromMethod === 'card_wire') {
				setIsLoading(false);
				return navigate('/iban/details', { state: { cardId: selectedCard, isCard: true } });
			}

			const response = await cardTopUpAccountAPI({
				toMethod: selectedToMethod === 'paytora_eur' ? 'paytora' : selectedToMethod,
				fromMethod: selectedFromMethod === 'paytora_eur' ? 'paytora' : selectedFromMethod,
				amount: parseInt(amount),
				cardId: selectedFromMethod === 'card' || selectedToMethod === 'card' ? selectedCard : null,
				pincode,
			});

			if (!response.isSuccess) {
				setIsLoading(false);

				return setErrMsg(response.error ?? t('errors.server.GENERAL_ERROR'));
			}

			setIsSuccessModalOpen(true);

			setIsLoading(false);
		}
	};

	const getFeesByTypeAndMethod = async (fromMethod) => {
		setPtotalFee(0);
		setTotalFee(0);
		let type = null;
		if (selectedToMethod === 'paytora_eur') {
			type = fromMethod === 'card' ? 'paytora_deposit_from_card' : fromMethod === 'iban' ? 'paytora_deposit_from_iban' : null;
		}

		if (selectedToMethod === 'card') {
			type = fromMethod === 'paytora_eur' ? 'card_deposit_from_paytora' : fromMethod === 'iban' ? 'card_deposit_from_iban' : null;
		}
		if (selectedToMethod === 'iban') {
			type = fromMethod === 'card' ? 'iban_deposit_from_card' : fromMethod === 'paytora_eur' ? 'iban_deposit_from_paytora' : null;
		}

		if (type === null) {
			setPtotalFee(0);
			setTotalFee(0);
			return;
		}

		const feesResponse = await getFeeByType({ type });

		if (feesResponse.isSuccess) {
			feesResponse.data.forEach((fee) => {
				if (fee.kind === 'percentage') setPtotalFee(fee.cost);
				else setTotalFee(fee.cost);
			});
		}
	};

	useEffect(() => {
		const getUserCards = async () => {
			setIsLoading(true);
			const response = await getUserCardsAPI();
			if (response.isSuccess) {
				setUserCards(response.cards);
				if (response.cards.length > 0) setSelectedCard(response.cards[0]._id);
			}

			setIsLoading(false);
		};

		getUserCards();
	}, []);

	if (currency && currency !== 'USD' && currency !== 'EUR') return <NotFound404 />;

	return isLoading || ibanIsLoading ? (
		<Loader />
	) : (
		<div className="flex flex-col justify-between p-5 h-screen" dir={langDir}>
			<div>
				<Header title={t('pages.deposit.header')} />

				<AccountDetailsSubHeader
					showBalance={false}
					showAmount={true}
					amount={totalFee > 0 && amount > 0 && amount - (amount * pTotalFee) / 100 - totalFee}
				/>

				<p className="text-lg mb-[10px]">{t('pages.deposit.title')}</p>
				<p className="text-darkGray">{t('pages.deposit.label.selectToMethod')}</p>

				<SelectInput
					name="toMethod"
					defaultValue={{
						value: selectedToMethod,
						label: isIban && !hasIban ? '' : toMethods.find((method) => method.value === selectedToMethod).label,
					}}
					onChange={(value) => {
						if (!currency && !isIban) {
							setSelectedToMethod(value);
							setSelectedFromMethod(null);
						}
					}}
					options={
						currency || isIban
							? []
							: toMethods.map((opt) => ({
									value: opt.value,
									label: opt.label,
							  }))
					}
				/>

				{selectedToMethod === 'card' && (
					<div>
						<p className="text-darkGray mt-[20px]">{t('pages.deposit.label.selectCardTo')}</p>
						<SelectInput
							name="cards"
							onChange={setSelectedCard}
							options={userCards.map((card) => ({ value: card._id, label: card.pan + ' - ' + card.balance + '€' }))}
							defaultValue={{ value: userCards[0]._id, label: userCards[0].pan + ' - ' + userCards[0].balance + '€' }}
						/>
					</div>
				)}

				<p className="text-lg mt-[40px] mb-[10px]">{t('pages.deposit.label.selectFromMethod')}</p>
				{fromMethods
					.filter((method) => method.method === selectedToMethod)
					.flatMap((method) =>
						method.options.map((option) => (
							<div className="flex flex-col" key={option.value}>
								<SelectButton
									text={
										option.value === 'other' ? (
											<div className="flex justify-center items-center">
												{option.label}
												<img src="/assets/card/card_mastercard.svg" className="w-[30px] ms-5" />
												<img src="/assets/card/card_visa.svg" className="w-[30px] " />
											</div>
										) : (
											option.label
										)
									}
									onClick={async () => {
										setSelectedFromMethod(option.value);
										await getFeesByTypeAndMethod(option.value);
									}}
									isSelected={option.value === selectedFromMethod}
									iconPath={option.value === selectedFromMethod ? 'icons/rainbow_icon.svg' : 'welcome/homeIcon.svg'}
								/>
							</div>
						))
					)}
				{selectedFromMethod === 'card' && (
					<div>
						<p className="text-darkGray mt-[20px]">{t('pages.deposit.label.selectCardFrom')}</p>
						<SelectInput
							name="cards"
							onChange={setSelectedCard}
							options={userCards.map((card) => ({ value: card._id, label: card.pan + ' - ' + card.balance + '€' }))}
							defaultValue={{ value: userCards[0]._id, label: userCards[0].pan + ' - ' + userCards[0].balance + '€' }}
						/>
					</div>
				)}
				{selectedFromMethod && selectedFromMethod !== 'wire' && selectedFromMethod !== 'card_wire' && (
					<div>
						<p className="text-darkGray mt-5">
							{t('pages.deposit.label.amount')} {selectedToMethod === 'paytora_usd' ? '($)' : '(€)'}
						</p>

						<Input placeholder="0.00" type="number" value={amount} onChange={(value) => setAmount(value)} inputMode="numeric" pattern="[0-9]" />
					</div>
				)}
				<div className="">
					{totalFee > 0 && amount > 0 && <p className="text-red">{`Fees: €${totalFee} ${pTotalFee > 0 ? ' + ' + pTotalFee + '%' : ''}`}</p>}
				</div>
			</div>

			<PageLogo />

			<div>
				{errMsg && <ErrorBubble error={errMsg} />}
				<PrimaryButton
					text={t('buttons.makeDeposit')}
					textColor="text-white"
					iconPath="icons/arrow_in_white.svg"
					onClick={() => setIsPincodeModalOpen(true)}
					className="mb-5"
				/>
			</div>

			<PincodeModal
				isOpen={isPincodeModalOpen}
				onClose={() => {
					setIsPincodeModalOpen(false);
					// navigate(-1);
				}}
				onAction={async (pincode) => {
					await depositHandler(pincode);
					setIsPincodeModalOpen(false);
				}}
			/>

			<CustomModal
				isOpen={isSuccessModalOpen}
				title={t('modals.deposit.success.title')}
				subTitle={t('modals.deposit.success.subTitle')}
				children={
					<div>
						<p className="text-xxl text-center p-5 text-green">
							{selectedToMethod === 'paytora_usd' ? '$' : '€'}
							{numeral(amount - ((parseInt(amount) * pTotalFee) / 100 + totalFee)).format('0,0.00')}
						</p>
						<PrimaryButton
							text={t('buttons.close')}
							onClick={() => {
								setIsSuccessModalOpen(false);
								navigate('/');
							}}
							textColor="text-white"
						/>
					</div>
				}
			/>
		</div>
	);
}
