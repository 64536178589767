import { useTranslation } from 'react-i18next';

import CardAndIbanTransactionItem from './cardAndIbanTransactionItem';
import PaytoraTransactionItem from './paytoraTransactionItem';

export const TransactionsContainer = ({ transactions = [] }) => {
	const { t } = useTranslation();

	const langDir = localStorage.getItem('languageDir');

	return (
		<div className={`bg-white mt-[40px] rounded-[10px] h-full`} dir={langDir}>
			<div className="flex justify-between items-center p-5">
				<h1 className="text-lg font-bolder">{t('pages.transactions.title')}</h1>
			</div>
			{transactions.length === 0 ? (
				<h1 className="p-2 text-lg text-center">{t('pages.transactions.noTransactions')}</h1>
			) : (
				<div>
					{transactions.map((transaction) => {
						return transaction.kind === 'Paytora' ? (
							<PaytoraTransactionItem transaction={transaction} key={transaction.id} />
						) : (
							<CardAndIbanTransactionItem transaction={transaction} key={transaction.id} />
						);
					})}
				</div>
			)}
		</div>
	);
};
