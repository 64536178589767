import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { getUserKYCStatusAPI } from '../../services/kyc';
import { Header } from '../../components/navigation/header';
import { PrimaryButton } from '../../components/buttons/primary-button';
import { PageLogo } from '../../components/utils/pageLogo';
import { logout } from '../../services/auth';

export default function KYCInvestigate({ currentKycLevel }) {
	const { t } = useTranslation();

	const getAndUpdateKycStatus = async () => {
		const response = await getUserKYCStatusAPI();

		if (!response.isSuccess) return;

		if (response.data !== currentKycLevel) window.location.reload();
	};

	useEffect(() => {
		const interval = setInterval(() => {
			getAndUpdateKycStatus();
		}, 10000);

		return () => clearInterval(interval);
	}, []);

	return (
		<div>
			<div className="p-5">
				<Header title={t('pages.kyc.title')} showLanguage={false} showNonUserChat={true} showUserChat={false} />
				<div>
					<div className="flex justify-end mt-[10px]">
						<PrimaryButton text={t('buttons.logout')} onClick={() => logout()} className="!w-[80px]" textColor="text-white" />
					</div>
					<div className="mt-5 text-center">
						<p className="font-bold mb-2"> 🎉 Congratulations on completing your KYC process!</p>
						🥳 Thank you for submitting your details. <br /> Our team is now reviewing your information to ensure everything checks out.
						<br />
						This process usually takes up to 5 hours. <br />
						We appreciate your patience and will notify you as soon as the review is complete.
						<br />
						<div className="flex justify-center uppercase mt-5">
							<p>🎈 Please use the chat button</p>

							<img src="/assets/icons/chat.svg" className="w-[20px] ms-2" />
						</div>
						<p className="uppercase mb-5"> to send us a message with your registered email to speed up the process.</p>
						Thanks for being with us!
					</div>
					<div className="flex justify-center mt-10">
						<PageLogo />
					</div>
				</div>
			</div>
		</div>
	);
}
