import { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import IntercomUserChat from '../chats/intercomUserChat';

import { getUnreadMessagesCountAPI } from '../../services/messages';

export const DashboardHeader = () => {
	const navigate = useNavigate();

	const [hasMessages, setHasMessages] = useState(false);

	const langDir = localStorage.getItem('languageDir');

	useEffect(() => {
		const checkAvailableNotifications = async () => {
			const messagesResponse = await getUnreadMessagesCountAPI();
			if (messagesResponse.isSuccess) setHasMessages(messagesResponse.data > 0);
		};

		checkAvailableNotifications();
	}, []);

	return (
		<div className="mb-[50%]" dir={langDir}>
			<div className="h-[60px] text-black font-bold flex justify-between items-center relative z-10">
				<div className="flex">
					<img src="/assets/welcome/homeIcon.svg" className="w-[24px]" onClick={() => navigate('/')} alt="home" />

					<img
						src={hasMessages ? '/assets/icons/notification_on.svg' : '/assets/icons/notification_off.svg'}
						onClick={() => navigate('/messages', { state: { hasMessages: hasMessages } })}
						className="ms-[10px] w-[24px]"
						alt="notifications"
					/>
				</div>
				<IntercomUserChat isDashboard={true} />
				{/* <LanguageSwitcher logoBlack={false} /> */}
			</div>
			<img
				src="/assets/dashboard/main_header_logo.svg"
				className="absolute top-0 left-0 w-full h-auto object-cover z-0"
				alt="Header background logo"
			/>
		</div>
	);
};
