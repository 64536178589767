import React from 'react';

export const Input = React.forwardRef(
	({ maxLength, value, placeholder, type, onChange, className, disabled, onKeyDown, inputMode, pattern, name, id }, ref) => {
		return (
			<input
				key={id}
				id={id}
				name={name}
				ref={ref}
				maxLength={maxLength}
				value={value}
				className={`bg-lightGray w-full rounded-[6px] mb-[10px] h-[50px] p-5 text-md ${className}`}
				placeholder={placeholder}
				type={type}
				disabled={disabled}
				onKeyDown={(e) => (onKeyDown ? onKeyDown(e) : {})}
				onChange={(e) => onChange(e.target.value)}
				inputMode={inputMode}
				pattern={pattern}
			/>
		);
	}
);
