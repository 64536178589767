import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import numeral from 'numeral';

import NotFound404 from '../utils/404';

import { Header } from '../../components/navigation/header';
import { GradientButton } from '../../components/buttons/gradient_button';
import { TransactionsContainer } from '../../components/transactions/transactionsContainer';
import { BottomMenu } from '../../components/navigation/bottomMenu';

import { getUserTransactionsAPI } from '../../services/user';
import { useTranslation } from 'react-i18next';
import Loader from '../utils/loader';

export default function PayToraTransactions() {
	const { t } = useTranslation();
	const langDir = localStorage.getItem('languageDir');

	const navigate = useNavigate();

	const [isLoading, setIsLoading] = useState(false);

	const location = useLocation();

	const { currency, balance } = location.state || {};

	const [transactions, setTransactions] = useState([]);

	const currencySymbol = currency === 'USD' ? '$' : '€';

	useEffect(() => {
		const fetchTransactions = async () => {
			setIsLoading(true);
			const transactions = await getUserTransactionsAPI('paytora', null, currency);

			if (transactions.isSuccess) setTransactions(transactions.data);
			setIsLoading(false);
		};

		fetchTransactions();
	}, [currency]);

	if (!currency) return <NotFound404 />;

	return isLoading ? (
		<Loader />
	) : (
		<div className="flex flex-col justify-between bg-lightGreen p-5 h-screen" dir={langDir}>
			<div>
				<Header title={`${t('pages.paytoraTransactions.header.accountTransactions')}`} />

				<div className={`${currency === 'USD' ? 'bg-blue' : 'bg-purple'} p-5 mt-[10px] mb-[20px] rounded-[10px] w-full text-white`}>
					<div className="flex justify-between">
						<h1 className="font-bold text-md">
							{t('pages.paytoraTransactions.title.paytora')} {currencySymbol} {t('pages.paytoraTransactions.title.account')}
						</h1>
						<div>
							<h2 className="text-lg">{currencySymbol + numeral(balance).format('0,0.00')}</h2>
							<p className="text-sm text-end">{t('pages.paytoraTransactions.label.balance')}</p>
						</div>
					</div>
				</div>

				<h1 className="font-bold text-lg mb-[10px]">{t('pages.paytoraTransactions.title.makeAction')}</h1>
				<div className="flex space-x-2 text-lg">
					<GradientButton
						text={t('buttons.send')}
						onClick={() => navigate('/send', { state: { currency } })}
						iconPath="icons/arrow_out.svg"
						className="me-2"
					/>
					<GradientButton text={t('buttons.deposit')} onClick={() => navigate('/deposit', { state: { currency } })} iconPath="icons/arrow_in.svg" />
				</div>

				<TransactionsContainer transactions={transactions} />
			</div>

			<BottomMenu selected="" />
		</div>
	);
}
