import numeral from 'numeral';

import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import Loader from '../utils/loader';

import { useIbanStatus } from '../../hooks/useIbanStatus';

import { getFeeByType } from '../../services/fees';
import { cardTopUpAccountAPI } from '../../services/transactions';

import { fetchUserAccounts } from '../../store/accountsSlice';
import { Header } from '../../components/navigation/header';
import { Input } from '../../components/inputs/textInput';
import { PrimaryButton } from '../../components/buttons/primary-button';
import { SelectButton } from '../../components/buttons/selectButton';
import { ErrorBubble } from '../../components/utils/errorBubble';

import { PincodeModal } from '../../components/modals/pincodeModal';
import { PageLogo } from '../../components/utils/pageLogo';

import { CustomModal } from '../../components/modals/customModal';

import { AccountDetailsSubHeader } from '../../components/navigation/accountDetailsSubHeader';

export default function CardDepositPage() {
	const { t } = useTranslation();
	const langDir = localStorage.getItem('languageDir');

	const navigate = useNavigate();

	const dispatch = useDispatch();

	const { userWallets } = useSelector((state) => state.accounts);

	const { isLoading: ibanIsLoading, hasIban } = useIbanStatus();

	const fromMethods = [
		{
			label: t('pages.deposit.method.from.paytora.eur'),
			value: 'paytora',
		},

		...(hasIban ? [{ label: t('pages.deposit.method.from.iban'), value: 'iban' }] : []), // Add IBAN only if hasIban is true
		{
			label: t('pages.deposit.method.from.wire'),
			value: 'wire',
		},
	];

	const location = useLocation();
	const { cardId } = location.state || {};

	const isSubmitting = useRef(false);

	const [isPincodeModalOpen, setIsPincodeModalOpen] = useState(false);

	const [errMsg, setErrMsg] = useState('');

	const [amount, setAmount] = useState(null);

	const [selectedFromMethod, setSelectedFromMethod] = useState(null);

	const [totalFee, setTotalFee] = useState(0);
	const [pTotalFee, setPtotalFee] = useState(0);

	const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);

	const [isLoading, setIsLoading] = useState(false);

	const depositHandler = async (pincode) => {
		if (isSubmitting.current) return;

		isSubmitting.current = true;

		if (selectedFromMethod !== 'wire' && (!amount || amount <= 0)) return setErrMsg(t('errors.validations.invalidAmount'));

		if (!selectedFromMethod) return setErrMsg(t('errors.validations.paymentMethod'));

		setIsLoading(true);

		try {
			if (selectedFromMethod === 'wire') return navigate('/iban/details', { state: { cardId, isCard: true } });

			const response = await cardTopUpAccountAPI({
				toMethod: 'card',
				fromMethod: selectedFromMethod,
				amount: parseInt(amount),
				cardId,
				pincode,
			});

			if (!response.isSuccess) {
				return setErrMsg(response.error ?? t('errors.server.GENERAL_ERROR'));
			}

			setIsSuccessModalOpen(true);
		} catch (error) {
		} finally {
			isSubmitting.current = false;
			setIsLoading(false);
		}
	};

	const calculateFee = (amount, constantFee) => {
		const ranges = [
			{ min: 0, max: 200, multiplier: 1 / 5 }, // 1/5 of constantFee
			{ min: 200, max: 400, multiplier: 2 / 5 }, // 2/5 of constantFee
			{ min: 400, max: 600, multiplier: 3 / 5 }, // 3/5 of constantFee
			{ min: 600, max: 800, multiplier: 4 / 5 }, // 4/5 of constantFee
		];

		for (const range of ranges) {
			if (amount > range.min && amount <= range.max) {
				return constantFee * range.multiplier;
			}
		}

		return constantFee;
	};

	const getFeesByTypeAndMethod = async (fromMethod, valuedAmount) => {
		setPtotalFee(0);
		setTotalFee(0);
		let type = fromMethod === 'paytora' ? 'card_deposit_from_paytora' : fromMethod === 'iban' ? 'card_deposit_from_iban' : null;

		if (type === null) {
			setPtotalFee(0);
			setTotalFee(0);
			return;
		}

		const feesResponse = await getFeeByType({ type });

		if (feesResponse.isSuccess) {
			feesResponse.data.forEach((fee) => {
				if (fee.kind === 'percentage') setPtotalFee(fee.cost);
				else {
					const feeCost = calculateFee(valuedAmount, fee.cost);
					if (fromMethod === 'paytora') setTotalFee(feeCost);
					else setTotalFee(fee.cost);
				}
			});
		}
	};

	useEffect(() => {
		dispatch(fetchUserAccounts());
	}, [dispatch]);

	return isLoading || ibanIsLoading ? (
		<Loader />
	) : (
		<div className="flex flex-col justify-between p-5 h-screen" dir={langDir}>
			<div>
				<Header title={t('pages.card.deposit.header')} />

				<AccountDetailsSubHeader
					showBalance={false}
					showAmount={true}
					amount={totalFee > 0 && amount > 0 && amount - (amount * pTotalFee) / 100 - totalFee}
				/>

				<p className="text-lg mt-[40px] mb-[10px]">{t('pages.card.deposit.paymentMethod.title')}</p>
				{fromMethods.map((method, index) => (
					<div className="flex flex-col" key={index}>
						<SelectButton
							text={method.label}
							onClick={async () => {
								setSelectedFromMethod(method.value);
								// await getFeesByTypeAndMethod(method.value);
								setAmount('');
							}}
							isSelected={method.value === selectedFromMethod}
							iconPath={method.value === selectedFromMethod ? 'icons/rainbow_icon.svg' : 'welcome/homeIcon.svg'}
						/>
					</div>
				))}

				<div>
					{totalFee > 0 && amount > 0 && (
						<p className="text-red">{`${t('pages.card.deposit.label.fees')} €${totalFee} ${pTotalFee > 0 ? ' + ' + pTotalFee + '%' : ''}`}</p>
					)}
				</div>

				{selectedFromMethod && selectedFromMethod !== 'wire' && (
					<div>
						<p className="text-darkGray mt-5">{t('pages.card.deposit.label.amount')}</p>

						<Input
							placeholder="0.00"
							type="number"
							value={amount}
							onChange={(value) => {
								setAmount(value);
								getFeesByTypeAndMethod(selectedFromMethod, value);
							}}
							inputMode="numeric"
							pattern="[0-9]"
						/>
					</div>
				)}

				{selectedFromMethod === 'paytora' &&
					userWallets
						.filter((wallet) => wallet.currency === 'EUR')
						.map((wallet, index) => (
							<p key={index}>
								{t('pages.card.deposit.label.availableAmount')}
								{wallet.balance - 25 > 0 ? wallet.balance - 25 : 0}
							</p>
						))}
			</div>

			<PageLogo />
			<div>
				{errMsg && <ErrorBubble error={errMsg} />}
				<PrimaryButton
					text={t('buttons.deposit')}
					textColor="text-white"
					iconPath="icons/arrow_in_white.svg"
					onClick={() => setIsPincodeModalOpen(true)}
					className="mb-5"
				/>
			</div>

			<PincodeModal
				isOpen={isPincodeModalOpen}
				onClose={() => {
					setIsPincodeModalOpen(false);
				}}
				onAction={(pincode) => {
					depositHandler(pincode);
					setIsPincodeModalOpen(false);
				}}
			/>

			<CustomModal
				isOpen={isSuccessModalOpen}
				title={t('modals.deposit.success.title')}
				subTitle={t('modals.deposit.success.subTitle')}
				children={
					<div>
						<p className="text-xxl text-center p-5 text-green">
							€{numeral(amount - ((parseInt(amount) * pTotalFee) / 100 + totalFee)).format('0,0.00')}
						</p>
						<PrimaryButton
							text={t('buttons.close')}
							onClick={() => {
								setIsSuccessModalOpen(false);
								navigate('/');
							}}
							textColor="text-white"
						/>
					</div>
				}
			/>
		</div>
	);
}
