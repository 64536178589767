import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import useValidation from '../../hooks/useValidation';

import Loader from '../utils/loader';
import { getStorageToken } from '../../utils/functions/token';
import { registerConfirmSchema, registerSchema } from '../../utils/validations/auth/auth_schema';

import { register, registerConfirm } from '../../services/auth';

import { Input } from '../../components/inputs/textInput';
import { PrimaryButton } from '../../components/buttons/primary-button';
import PasswordInput from '../../components/inputs/passwordInput';
import { PincodeInput } from '../../components/inputs/pincodeInput';
import { Header } from '../../components/navigation/header';
import { ErrorBubble } from '../../components/utils/errorBubble';
import { InstructionBubble } from '../../components/utils/InstructionBubble';
import { PageLogo } from '../../components/utils/pageLogo';

export default function Register() {
	const navigate = useNavigate();

	const { t } = useTranslation();

	const currentDirection = localStorage.getItem('languageDir');

	const [isLoading, setIsLoading] = useState(false);

	const [nextPage, setNextPage] = useState(false);

	const { validationErrMsg: otpValidationErrMsg, validate: otpValidate } = useValidation(registerSchema(t));

	const { validationErrMsg, validate } = useValidation(registerConfirmSchema(t));

	const [apiErrMsg, setApiErrMsg] = useState('');

	const [pincodeValues, setPincodeValues] = useState(Array(6).fill(''));

	const [formData, setFormData] = useState({
		firstName: '',
		lastName: '',
		email: '',
		otp: '',
		password: '',
		affiliateCode: '',
		type: 'individual',
		confirmPassword: '',
		agreeToTerms: false,
		agreeToPrivacyPolicy: false,
	});

	const location = useLocation();

	const params = new URLSearchParams(location.search);
	const affiliateCode = params.get('affiliateCode');

	const handleCheckboxChange = (fieldName) => {
		setFormData((data) => ({ ...data, [fieldName]: !data[fieldName] }));
	};

	const handleRegister = async (event) => {
		event.preventDefault();

		const data = {
			type: formData.type,
			email: formData.email,
		};

		const isValid = otpValidate(data);
		if (!isValid) return;

		if (!isLoading) {
			setIsLoading(true);

			const response = await register(formData.type, formData.email);

			if (!response.isSuccess) {
				setApiErrMsg(t(`errors.server.${response.error}`));
			} else {
				setApiErrMsg('');
				setNextPage(true);
			}
		}
		setIsLoading(false);
	};

	const handleRegisterConfirm = async (event) => {
		event.preventDefault();

		const data = {
			email: formData.email,
			type: formData.type,
			otp: pincodeValues.join(''),
			password: formData.password,
			confirmPassword: formData.confirmPassword,
			affiliateCode: formData.affiliateCode,
		};

		const isValid = validate(data);
		if (!isValid) {
			setFormData((data) => ({
				...data,
				agreeToPrivacyPolicy: false,
				agreeToTerms: false,
			}));
			return;
		}

		if (!formData.agreeToTerms || !formData.agreeToPrivacyPolicy) {
			setApiErrMsg(t('errors.validations.termsAndPrivacy'));
			setIsLoading(false);
			return;
		}

		if (!isLoading) {
			setIsLoading(true);

			const response = await registerConfirm(data);

			if (!response.isSuccess) {
				setApiErrMsg(t(`errors.server.${response.error}`));
			} else {
				setApiErrMsg('');
				navigate(0);
			}
			setIsLoading(false);
		}
	};

	useEffect(() => {
		if (affiliateCode) {
			setFormData((prevState) => ({ ...prevState, affiliateCode }));
		}
	}, [location, affiliateCode]);

	useEffect(() => {
		const token = getStorageToken();

		if (token) {
			navigate('/');
		}
	}, [navigate]);

	return isLoading ? (
		<Loader />
	) : !nextPage ? (
		<form onSubmit={handleRegister} className="flex flex-col justify-between h-screen p-5">
			<div>
				<Header showUserChat={false} showNonUserChat={true} hasBack={!formData.affiliateCode} />
				<div className="flex flex-col items-center">
					<div dir={currentDirection}>
						<h1 className="text-xl font-bolder">{t('pages.register.title')}</h1>
						<p className="text-md mb-[27px]">{t('pages.register.subTitle')}</p>
					</div>

					<Input
						onChange={(value) => {
							setFormData((data) => ({ ...data, email: value.trim() }));
						}}
						placeholder={t('inputs.placeholders.email')}
						type="text"
						value={formData.email}
						className="lg:w-[29%]"
					/>

					{otpValidationErrMsg && <ErrorBubble error={otpValidationErrMsg} />}
					{apiErrMsg && <ErrorBubble error={apiErrMsg} />}
				</div>
			</div>

			<PageLogo />
			<div className="flex flex-col items-center">
				<PrimaryButton text={t('buttons.continue')} className="mb-[10px]" />

				{!formData.affiliateCode && (
					<div className="flex items-center" dir={currentDirection}>
						<img src="/assets/icons/info.svg" className="me-[12px]" alt="info" />
						<p className="text-sm">
							{t('pages.register.login.text')}
							<Link to="/login" className="text-gradient font-bold ms-2">
								{t('pages.register.login.link')}
							</Link>
						</p>
					</div>
				)}
			</div>
		</form>
	) : (
		<form onSubmit={handleRegisterConfirm} className="flex flex-col justify-between h-screen p-5">
			<div>
				<Header
					showUserChat={false}
					showNonUserChat={true}
					title={t('pages.register.header')}
					showLanguage={false}
					backNavigation={() => setNextPage(false)}
				/>
				<div className="lg:flex lg:flex-col items-center justify-center">
					<div dir={currentDirection}>
						<h1 className="text-xl font-bolder mb-5">{t('pages.register.otp.title')}</h1>
						<p className="text-md text-darkGray">{t('pages.register.otp.subTitle')}</p>
						<p className="text-md">{formData.email}</p>
					</div>

					<PincodeInput onChange={(value) => setPincodeValues(value)} pincode={pincodeValues} className="mt-[30px]" />

					{formData.affiliateCode && (
						<div className="text-md text-darkGray mt-[5px]" dir={currentDirection}>
							<p className="">{t('pages.register.affiliate.label')}</p>
							<Input disabled={true} placeholder={t('inputs.placeholders.affiliateCode')} type="text" value={formData.affiliateCode} />
						</div>
					)}

					<InstructionBubble title={t('passwordInstructions.title')} text={t('passwordInstructions.message')} className="mt-[5px]" />

					<div dir={currentDirection}>
						<h1 className="text-xl font-bolder mt-[20px] mb-[20px]">{t('pages.register.password.title')}</h1>

						<label className="text-md text-darkGray">{t('pages.register.password.label')}</label>
					</div>

					<PasswordInput
						placeholder={t('inputs.placeholders.password')}
						value={formData.password}
						onChange={(value) => {
							setFormData((data) => ({
								...data,
								password: value,
							}));
						}}
					/>

					<div dir={currentDirection}>
						<label className="text-md text-darkGray">{t('pages.register.passwordConfirm.label')}</label>
					</div>
					<PasswordInput
						value={formData.confirmPassword}
						placeholder={t('inputs.placeholders.confirmPassword')}
						onChange={(value) => {
							setFormData((data) => ({
								...data,
								confirmPassword: value,
							}));
						}}
					/>
					<div>
						<div className="mt-5" dir={currentDirection}>
							<input
								type="checkbox"
								id="termsCheckbox"
								checked={formData.agreeToTerms}
								onChange={() => handleCheckboxChange('agreeToTerms')}
								className="me-2"
							/>
							<label htmlFor="termsCheckbox" className="ml-2">
								{t('termsAndPrivacy.title')}
								<span className="text-gradient cursor-pointer m-2" onClick={() => window.open('/terms', '_blank')}>
									{t('termsAndPrivacy.terms')}
								</span>
							</label>
						</div>
						<div className="mb-[20px]" dir={currentDirection}>
							<input
								type="checkbox"
								id="privacyCheckbox"
								checked={formData.agreeToPrivacyPolicy}
								onChange={() => handleCheckboxChange('agreeToPrivacyPolicy')}
								className="me-2"
							/>
							<label htmlFor="privacyCheckbox" className="ml-2">
								{t('termsAndPrivacy.title')}
								<span className="text-gradient cursor-pointer m-2" onClick={() => window.open('/privacy', '_blank')}>
									{t('termsAndPrivacy.privacy')}
								</span>
							</label>
						</div>
					</div>
				</div>
			</div>

			<PageLogo className="h-[50px] mb-5" />

			<div className="text-center">
				{validationErrMsg && <ErrorBubble error={validationErrMsg} />}
				{apiErrMsg && <ErrorBubble error={apiErrMsg} />}
				{otpValidationErrMsg && <ErrorBubble error={otpValidationErrMsg} />}
				<PrimaryButton text={t('buttons.signup')} className="mb-[10px]" />
			</div>
		</form>
	);
}
