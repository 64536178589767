import { z } from 'zod';

// export const IbanDataWithdrawSchema = (t) =>
// 	z.object({
// 		wireType: z
// 			.string()
// 			.trim()
// 			.min(2, { message: t('errors.validations.wireType') }),
// 		receiverFullName: z
// 			.string()
// 			.trim()
// 			.min(2, { message: t('errors.validations.fullName') }),
// 		receiverIBAN: z
// 			.string()
// 			.trim()
// 			.min(1, { message: t('errors.validations.iban') }),
// 		receiverBankName: z
// 			.string()
// 			.trim()
// 			.min(1, { message: t('errors.validations.receiverBankName') }),
// 		receiverBankBranch: z
// 			.string()
// 			.trim()
// 			.min(2, { message: t('errors.validations.receiverBankBranch') }),
// 		// receiverCity: z
// 		// 	.string()
// 		// 	.trim()
// 		// 	.min(1, { message: t('errors.validations.city') }),
// 		// receiverBIC: z.string().trim().optional(),
// 	});
// // .refine(
// // 	(data) => {
// // 		// Require receiverBIC if wireType is 'SWIFT'
// // 		return data.wireType !== 'SWIFT' || data.receiverBIC;
// // 	},
// // 	{
// // 		message: t('errors.validations.bic'),
// // 		path: ['receiverBIC'],
// // 	}
// // );

export const IbanDataWithdrawSchema = (t) =>
	z
		.object({
			wireType: z
				.string()
				.trim()
				.min(2, { message: t('errors.validations.wireType') }),
			receiverFullName: z
				.string()
				.trim()
				.min(2, { message: t('errors.validations.fullName') }),
			receiverIBAN: z
				.string()
				.trim()
				.min(1, { message: t('errors.validations.iban') }),
			receiverStreetAndNumber: z.string().trim().optional(),
			receiverPostalCode: z.string().trim().optional(),
			receiverCity: z.string().trim().optional(),
			receiverBIC: z.string().trim().optional(),
			receiverBankName: z
				.string()
				.trim()
				.min(1, { message: t('errors.validations.bankName') }),
			receiverBankBranch: z.string().trim().optional(),
		})
		// Additional fields required if wireType !== 'INSTANT'
		.refine((data) => data.wireType === 'INSTANT' || !!data.receiverStreetAndNumber, {
			message: t('errors.validations.streetAndNumber'),
			path: ['receiverStreetAndNumber'],
		})
		.refine((data) => data.wireType === 'INSTANT' || !!data.receiverPostalCode, {
			message: t('errors.validations.postalCode'),
			path: ['receiverPostalCode'],
		})
		.refine((data) => data.wireType === 'INSTANT' || !!data.receiverCity, {
			message: t('errors.validations.city'),
			path: ['receiverCity'],
		})
		.refine((data) => data.wireType === 'INSTANT' || !!data.receiverBIC, {
			message: t('errors.validations.bic'),
			path: ['receiverBIC'],
		})
		// receiverBankBranch required only if wireType === 'INSTANT'
		.refine((data) => data.wireType !== 'INSTANT' || !!data.receiverBankBranch, {
			message: t('errors.validations.bankBranch'),
			path: ['receiverBankBranch'],
		});
