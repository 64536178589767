import { apiRequest } from './api';

// export async function getUserCardsBalanceAPI() {
// 	try {
// 		const response = await apiRequest('/user/cards/balances', 'GET', null);

// 		return { isSuccess: true, balance: response.data };
// 	} catch (error) {
// 		return { isSuccess: false, error: error.message };
// 	}
// }

export async function getUserSpecificCardAPI(cardId) {
	try {
		const response = await apiRequest('/user/card/balance/' + cardId, 'GET', null);

		return { isSuccess: true, balance: response.data };
	} catch (error) {
		return { isSuccess: false, error: error.message };
	}
}

export async function getUserCardsAPI() {
	try {
		const response = await apiRequest('/user/cards', 'GET', null);

		return { isSuccess: true, cards: response.data.cards, templates: response.data.templates };
	} catch (error) {
		return { isSuccess: false, error: error.message };
	}
}

export async function getUserCardData(cardId, pincode) {
	try {
		const response = await apiRequest('/user/card/show/' + cardId, 'POST', { pincode });

		return { isSuccess: true, card: response.data };
	} catch (error) {
		return { isSuccess: false, error: error.message };
	}
}

export async function cardApplyAPI(cardId, data = null) {
	try {
		await apiRequest(`/user/card/apply/${cardId}`, 'POST', data);
		return { isSuccess: true };
	} catch (error) {
		return { isSuccess: false, error: error.message };
	}
}

export async function topUpUserCardAPI(data) {
	try {
		await apiRequest(`/user/card/topup`, 'POST', data);
		return { isSuccess: true };
	} catch (error) {
		return { isSuccess: false, error: error.message };
	}
}

export async function freezeVirtualCardAPI(cardId, pincode) {
	try {
		await apiRequest(`/user/card/freeze/${cardId}`, 'PUT', { pincode });
		return { isSuccess: true };
	} catch (error) {
		return { isSuccess: false, error: error.message };
	}
}

export async function unFreezeVirtualCard(cardId, pincode) {
	try {
		await apiRequest(`/user/card/unfreeze/${cardId}`, 'PUT', { pincode });
		return { isSuccess: true };
	} catch (error) {
		return { isSuccess: false, error: error.message };
	}
}

export async function terminateVirtualCard(cardId, pincode) {
	try {
		await apiRequest(`/user/card/terminate/${cardId}`, 'PUT', { pincode });
		return { isSuccess: true };
	} catch (error) {
		return { isSuccess: false, error: error.message };
	}
}

export async function activatePhysicalCard(cvv, digits) {
	try {
		await apiRequest(`/user/card/activate/`, 'POST', { cvv, digits });
		return { isSuccess: true };
	} catch (error) {
		return { isSuccess: false, error: error.message };
	}
}

export async function showPhysicalCardPin(cardId) {
	try {
		const response = await apiRequest(`/user/card/showPin/${cardId}`, 'GET');
		return { isSuccess: true, data: response.pin };
	} catch (error) {
		return { isSuccess: false, error: error.message };
	}
}

export async function changePhysicalCardPin(cardId, cardPin) {
	try {
		await apiRequest(`/user/card/changePin/${cardId}`, 'PATCH', { cardPin });
		return { isSuccess: true };
	} catch (error) {
		return { isSuccess: false, error: error.message };
	}
}

export async function createVerestroUserAndVerifyKYC(addressData) {
	try {
		await apiRequest(`/user/card/verify/user`, 'POST', addressData);
		return { isSuccess: true };
	} catch (error) {
		return { isSuccess: false, error: error.message };
	}
}
