import { useEffect, useState, useCallback } from 'react';

import { Header } from '../../../components/navigation/header';
import { AccountDetailsSubHeader } from '../../../components/navigation/accountDetailsSubHeader';

import { PrimaryButton } from '../../../components/buttons/primary-button';
import { Input } from '../../../components/inputs/textInput';
import SelectInput from '../../../components/inputs/selectInput';
import { PageLogo } from '../../../components/utils/pageLogo';
import { getFeeByType } from '../../../services/fees';
import useValidation from '../../../hooks/useValidation';
import { IbanDataWithdrawSchema } from '../../../utils/validations/withdraw/withdraw-schema';
import { ErrorBubble } from '../../../components/utils/errorBubble';
import { getDetailsByIbanAPI, withdrawalAPI } from '../../../services/ibans';
import { useLocation } from 'react-router-dom';
import Loader from '../../utils/loader';
import { swapCurrencyAPI } from '../../../services/swap';
import { CustomModal } from '../../../components/modals/customModal';
import { useTranslation } from 'react-i18next';
import numeral from 'numeral';
import { PincodeModal } from '../../../components/modals/pincodeModal';

const regularCurrencies = [
	{ label: '🇷🇴 RON - NOVO LEU', value: 'RON' },
	{ label: '🇵🇱 PLN - POLAND ZLOTY', value: 'PLN' },
	{ label: '🇿🇦 ZAR - RAND (SOUTH AFRICA)', value: 'ZAR' },
	{ label: '🇸🇬 SGD - SINGAPORE DOLLAR', value: 'SGD' },
	{ label: '🇸🇪 SEK - SWEDISH KRONA', value: 'SEK' },
	{ label: '🇨🇭 CHF - SWISS FRANCS', value: 'CHF' },
	{ label: '🇹🇳 TND - TUNISIAN DINAR', value: 'TND' },
	{ label: '🇺🇸 USD - UNITED STATES DOLLAR', value: 'USD' },
	{ label: '🇹🇷 TRY - YENI TURKISH LIRA', value: 'TRY' },
	{ label: '🇨🇳 CNY - YUAN RENMINBI DA CHINA', value: 'CNY' },
	{ label: '🇩🇿 DZD - ALGERIAN DINAR', value: 'DZD' },
	{ label: '🇦🇺 AUD - AUSTRALIAN DOLLAR', value: 'AUD' },
	{ label: '🇹🇭 THB - BAHT TAILANDES', value: 'THB' },
	{ label: '🇨🇦 CAD - CANADIAN DOLLAR', value: 'CAD' },
	{ label: '🇮🇸 ISK - COROA DA ISLÂNDIA', value: 'ISK' },
	{ label: '🇨🇿 CZK - CZECH KORUNA', value: 'CZK' },
	{ label: '🇩🇰 DKK - DANISH KRONE', value: 'DKK' },
	{ label: '🇬🇧 GBP - GREAT BRITISH POUND', value: 'GBP' },
	{ label: '🇭🇰 HKD - HONG KONG DOLLAR', value: 'HKD' },
	{ label: '🇭🇺 HUF - HUNGARIAN FORINT', value: 'HUF' },
	{ label: '🇮🇱 ILS - ISRAELI NEW SHEKEL', value: 'ILS' },
	{ label: '🇯🇵 JPY - JAPANESE YEN', value: 'JPY' },
	{ label: '🇲🇴 MOP - MACAU PATACA', value: 'MOP' },
	{ label: '🇲🇦 MAD - MAROCCAN DIRHAM', value: 'MAD' },
	{ label: '🇲🇽 MXN - MEXICAN PESO', value: 'MXN' },
	{ label: '🇳🇿 NZD - NEW ZEALAND DOLLAR', value: 'NZD' },
	{ label: '🇳🇴 NOK - NORWEGIAN KRONE', value: 'NOK' },
];

const instantCurrencies = [
	{ label: '🇰🇭 KHR - CAMBODIAN RIEL', value: 'KHR' },
	{ label: '🇨🇳 CNY - YUAN RENMINBI (CHINA)', value: 'CNY' },
	{ label: '🇧🇩 BDT - BANGLADESHI TAKKA', value: 'BDT' },
	{ label: '🇮🇳 INR - INDIAN RUPEE', value: 'INR' },
	{ label: '🇮🇩 IDR - INDONESIAN RUPIAH', value: 'IDR' },
	{ label: '🇯🇵 JPY - JAPANESE YEN', value: 'JPY' },
	{ label: '🇲🇾 MYR - MALAYSIAN RINGGIT', value: 'MYR' },
	{ label: '🇵🇭 PHP - PHILIPPINE PESO', value: 'PHP' },
	{ label: '🇰🇷 KRW - SOUTH KOREAN WON', value: 'KRW' },
	{ label: '🇹🇼 TWD - TAIWANESE DOLLAR', value: 'TWD' },
	{ label: '🇹🇭 THB - THAI BAHT', value: 'THB' },
	{ label: '🇻🇳 VND - VIETNAMESE DONG', value: 'VND' },

	{ label: '🇧🇼 BWP - BOTSWANAN PULA', value: 'BWP' },
	{ label: '🇨🇫 XAF - CENTRAL AFRICAN CFA FRANC', value: 'XAF' },
	{ label: '🇨🇩 CDF - CONGOLESE FRANC (DRC)', value: 'CDF' },
	{ label: '🇬🇭 GHS - GHANAIAN CEDI', value: 'GHS' },
	{ label: '🇰🇪 KES - KENYAN SHILLING', value: 'KES' },
	{ label: '🇲🇼 MWK - MALAWIAN KWACHA', value: 'MWK' },
	{ label: '🇳🇬 NGN - NIGERIAN NAIRA', value: 'NGN' },
	{ label: '🇷🇼 RWF - RWANDAN FRANC', value: 'RWF' },
	{ label: '🇿🇦 ZAR - SOUTH AFRICAN RAND', value: 'ZAR' },
	{ label: '🇦🇪 AED - UNITED ARAB EMIRATES DIRHAM', value: 'AED' },
	{ label: '🇺🇬 UGX - UGANDAN SHILLING', value: 'UGX' },
	{ label: '🇫🇷 XOF - WEST AFRICAN CFA FRANC', value: 'XOF' },
	{ label: '🇿🇲 ZMW - ZAMBIAN KWACHA', value: 'ZMW' },

	{ label: '🇦🇺 AUD - AUSTRALIAN DOLLAR', value: 'AUD' },
	{ label: '🇨🇦 CAD - CANADIAN DOLLAR', value: 'CAD' },
	{ label: '🇭🇰 HKD - HONG KONG DOLLAR', value: 'HKD' },
	{ label: '🇸🇬 SGD - SINGAPORE DOLLAR', value: 'SGD' },
	{ label: '🇬🇧 GBP - GREAT BRITISH POUND', value: 'GBP' },
	{ label: '🇺🇸 USD - UNITED STATES DOLLAR', value: 'USD' },
];

export default function Withdrawal() {
	const location = useLocation();
	const { type = 'iban' } = location.state || {};

	const { t } = useTranslation();

	const langDir = localStorage.getItem('languageDir');

	const { validationErrMsg, validate } = useValidation(IbanDataWithdrawSchema(t));

	const [wireType, setWireType] = useState('SEPA');

	const [errMsg, setErrMsg] = useState('');
	const [isLoading, setIsLoading] = useState(false);

	const [amount, setAmount] = useState(0);
	const [currency, setCurrency] = useState('EUR');
	const [reason, setReason] = useState('');

	const [step, setStep] = useState(0);

	const [totalFee, setTotalFee] = useState(null);
	const [pTotalFee, setPtotalFee] = useState(null);

	const [isVerified, setIsVerified] = useState(false);

	const [exchangedAmount, setExchangedAmount] = useState(0);

	const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
	const [isPincodeModalOpen, setIsPincodeModalOpen] = useState(false);

	const [bankDetails, setBankDetails] = useState({
		wireType,
		receiverFullName: '',
		receiverIBAN: '',
		receiverStreetAndNumber: '',
		receiverPostalCode: '',
		receiverCity: '',
		receiverBIC: '',
		receiverBankName: '',
		receiverBankBranch: '',
	});

	const handleBankDetailsChange = (field, value) => {
		if (currency === 'CNY') {
			// Validate if the input contains only Chinese characters
			const isValidChinese = /^[\u4e00-\u9fff\s]+$/.test(value);
			if (!isValidChinese && value !== '') {
				setErrMsg('information must be in Chinese.');
				return; // Stop update if invalid
			} else {
				setErrMsg(''); // Clear the error if valid
			}
		}

		// Update the corresponding field in bank details
		setBankDetails((data) => ({ ...data, [field]: value }));
	};

	const getDetailsByIban = async () => {
		const allowedCountries = [
			'AT', // Austria
			'BE', // Belgium
			'BG', // Bulgaria
			'HR', // Croatia
			'CY', // Cyprus
			'CZ', // Czech Republic
			'DK', // Denmark
			'EE', // Estonia
			'FI', // Finland
			'FR', // France
			'DE', // Germany
			'GR', // Greece
			'HU', // Hungary
			'IE', // Ireland
			'IT', // Italy
			'LV', // Latvia
			'LT', // Lithuania
			'LU', // Luxembourg
			'MT', // Malta
			'NL', // Netherlands
			'PL', // Poland
			'PT', // Portugal
			'RO', // Romania
			'SK', // Slovakia
			'SI', // Slovenia
			'ES', // Spain
			'SE', // Sweden
		];

		if (!bankDetails.receiverIBAN) return setErrMsg(t('errors.validations.iban'));

		const response = await getDetailsByIbanAPI(bankDetails.receiverIBAN);

		if (!response.isSuccess) {
			setBankDetails((data) => ({
				...data,
				receiverStreetAndNumber: '',
				receiverPostalCode: '',
				receiverCity: '',
			}));

			setIsVerified(false);

			return setErrMsg(response.error);
		}

		setErrMsg('');

		if (wireType === 'SEPA' && !allowedCountries.includes(response.data.countryCode)) {
			setIsVerified(false);
			return setErrMsg('We are not supporting this country using SEPA method plesae return and choose another method (SWIFT/INSTANT)');
		}

		setBankDetails((data) => ({
			...data,
			receiverStreetAndNumber: response.data.address,
			receiverPostalCode: response.data.postalCode,
			receiverCity: response.data.city,
			receiverBankName: response.data.bankName,
			receiverBIC: response.data.bic,
		}));

		setIsVerified(true);
	};

	const handleWireTypeChange = (event) => {
		setWireType(event.target.value);
		const validCurrency =
			event.target.value === 'INSTANT' ? instantCurrencies[0].value : event.target.value === 'SWIFT' ? regularCurrencies[0].value : 'EUR';
		setCurrency(validCurrency);
		getFees(event.target.value, 'EUR');
	};

	const getFees = useCallback(
		async (type = wireType) => {
			const typeFilter =
				type === 'SWIFT' ? 'iban_withdraw_swift' : type === 'SEPA' ? 'iban_withdraw_sepa' : type === 'INSTANT' ? 'iban_withdraw_instant' : null;
			const feesResponse = await getFeeByType({ type: typeFilter });

			if (feesResponse.isSuccess) {
				feesResponse.data.forEach((fee) => {
					if (fee.kind === 'percentage') setPtotalFee(fee.cost);
					else setTotalFee(fee.cost);
				});
			}
		},
		[wireType]
	);

	const getRates = async (amount) => {
		if (!isLoading) {
			if (amount && amount > 0) {
				const response = await swapCurrencyAPI({ fromCurrency: 'EUR', toCurrency: currency, amount, withfee: false });

				if (!response.isSuccess) {
					setIsLoading(false);
					return;
				}
				setExchangedAmount(response.data);
			}
		}
	};

	const handleBankWire = async (pincode) => {
		const data = {
			bankDetails,
			amount,
			currency,
			reason,
			fromMethod: type,
			wireType,
			pincode,
		};

		if (!amount || amount <= 0) return setErrMsg(t('errors.validations.invalidAmount'));

		if (wireType === 'INSTANT' && amount < 1000) return setErrMsg(t('errors.validations.withdrawExchangedAmount'));

		if (!reason) return setErrMsg(t('errors.validations.reason'));

		if (!isLoading) {
			setIsLoading(true);
			const response = await withdrawalAPI(data);

			if (!response.isSuccess) {
				setIsLoading(false);
				return setErrMsg(response.error ?? t('errors.server.GENERAL_ERROR'));
			}
		}

		setIsLoading(false);

		setIsSuccessModalOpen(true);
	};

	const getAmountWithFees = (amount) => {
		const percentageFee = amount * (pTotalFee / 100);
		const fixedFee = totalFee;

		const calculatedFee = percentageFee + fixedFee;

		return calculatedFee;

		// const fee = amount * (pTotalFee / 100) > totalFee ? amount * (pTotalFee / 100) : totalFee;
		// return fee;
	};

	useEffect(() => {
		// getMethodFee();
		getFees();
	}, [getFees]);

	return isLoading ? (
		<Loader />
	) : (
		<div className="p-5 h-screen flex flex-col justify-between">
			{step === 0 && (
				<>
					<div dir={langDir}>
						<Header title={type === 'paytora' ? t('pages.withdrawal.header.paytora') : t('pages.withdrawal.header.iban')} />
						<AccountDetailsSubHeader />
						<h1 className="text-xl font-bold mb-[10px]">{t('pages.withdrawal.step1.title')}</h1>
						<div className="flex flex-col mb-5">
							<h2 className="text-lg  mb-2">{t('pages.withdrawal.step1.label.wireType')}</h2>
							<label className="space-x-2">
								<input type="radio" name="wireType" value="SEPA" checked={wireType === 'SEPA'} onChange={handleWireTypeChange} className="me-1" />
								<span>
									SEPA <br /> ({t('pages.withdrawal.sepa.explain')})
								</span>
							</label>
							<label className="space-x-2">
								<input type="radio" name="wireType" value="SWIFT" checked={wireType === 'SWIFT'} onChange={handleWireTypeChange} className="me-1" />
								<span>
									SWIFT <br /> ({t('pages.withdrawal.swift.explain')})
								</span>
							</label>
							<label className="space-x-2">
								<input
									type="radio"
									name="wireType"
									value="INSTANT"
									checked={wireType === 'INSTANT'}
									onChange={handleWireTypeChange}
									className="me-1"
								/>
								<span>
									INSTANT <br /> ({t('pages.withdrawal.swift.explain')})
								</span>
							</label>
						</div>
						<div className="text-lg">
							<p className="font-bold">{t('pages.withdrawal.step1.label.fees')}</p>
							<p className="">
								{pTotalFee}% {totalFee > 0 && '+ €' + totalFee}
							</p>
						</div>

						{wireType !== 'SEPA' && (
							<div>
								<p className="text-md mt-2 mb-2">{t('pages.withdrawal.step3.label.warning')}</p>
								<p>{t('pages.withdrawal.step3.label.currency')}</p>
							</div>
						)}
						{wireType === 'INSTANT' && (
							<SelectInput
								defaultValue={{
									value: instantCurrencies[0].value,
									label: instantCurrencies[0].label,
								}}
								onChange={(value) => {
									setCurrency(value);
									setAmount(0);
									setExchangedAmount(0);
								}}
								options={instantCurrencies.map((opt) => ({
									value: opt.value,
									label: opt.label,
								}))}
							/>
						)}
						{wireType === 'SWIFT' && (
							<SelectInput
								defaultValue={{
									value: regularCurrencies[0].value,
									label: regularCurrencies[0].label,
								}}
								onChange={(value) => {
									setCurrency(value);
									setAmount(0);
									setExchangedAmount(0);
								}}
								options={regularCurrencies.map((opt) => ({
									value: opt.value,
									label: opt.label,
								}))}
							/>
						)}
						{wireType !== 'SEPA' && (
							<p className="text-red">
								{wireType === 'INSTANT' ? t('pages.withdrawal.wire.method.instant') : t('pages.withdrawal.wire.method.regular')}
							</p>
						)}
					</div>

					<PageLogo />
					{/* <p className="text-red text-bold">{t('pages.withdrawal.step1.label.warning').toUpperCase()}</p> */}
					<PrimaryButton
						text={t('buttons.next')}
						className="mb-[10px]"
						textColor="text-white"
						onClick={() => {
							setStep(step + 1);
							// setCurrency(wireType === 'SEPA' ? 'EUR' : wireType === 'INSTANT' ? instantCurrencies[0].value : regularCurrencies[0].value);
						}}
					/>
				</>
			)}

			{step === 1 && (
				<>
					<div dir={langDir}>
						<Header
							showLanguage={false}
							title={type === 'paytora' ? t('pages.withdrawal.header.paytora') : t('pages.withdrawal.header.iban')}
							backNavigation={
								step === 0
									? null
									: () => {
											setBankDetails({
												wireType,
												receiverFullName: '',
												receiverIBAN: '',
												receiverStreetAndNumber: '',
												receiverPostalCode: '',
												receiverCity: '',
												receiverBIC: '',
												receiverBankName: '',
												receiverBankBranch: '',
											});
											setIsVerified(false);
											setWireType('SEPA');
											setCurrency('EUR');
											setErrMsg('');
											setStep(step - 1);
									  }
							}
						/>
						<AccountDetailsSubHeader />

						<h1 className="text-lg mb-[10px]">{t('pages.withdrawal.step2.title')}</h1>
						{currency === 'CNY' && <p className="mt-2 mb-2 text-red">{t('pages.withdrawal.step2.label.onlyChinese')}</p>}
						<p>{t('pages.withdrawal.step2.label.fullName')}</p>
						<Input
							onChange={(value) => handleBankDetailsChange('receiverFullName', value)}
							placeholder="Ex. John Doe"
							type="text"
							value={bankDetails.receiverFullName}
						/>

						<p>{t('pages.withdrawal.step2.label.iban')} / IBAN</p>
						<Input
							onChange={(value) => {
								setBankDetails((data) => ({ ...data, receiverIBAN: value }));
								setIsVerified(false);
							}}
							placeholder="Ex. PL12345678910"
							type="text"
							value={bankDetails.receiverIBAN}
						/>

						{wireType === 'INSTANT' && (
							<>
								<p>{t('pages.withdrawal.step2.label.bankName')}</p>
								<Input
									onChange={(value) => handleBankDetailsChange('receiverBankName', value)}
									placeholder="Ex. Bank Of China"
									type="text"
									value={bankDetails.receiverBankName}
								/>
								<p>{t('pages.withdrawal.step2.label.bankBranch')}</p>
								<Input
									onChange={(value) => handleBankDetailsChange('receiverBankBranch', value)}
									placeholder="Ex. Shenzhen Branch"
									type="text"
									value={bankDetails.receiverBankBranch}
								/>
							</>
						)}
						{wireType !== 'INSTANT' && isVerified && (
							<>
								<p>{t('pages.withdrawal.step2.label.bankName')}</p>
								<Input
									onChange={(value) => handleBankDetailsChange('receiverBankName', value)}
									placeholder="Ex. Bank Of China"
									type="text"
									value={bankDetails.receiverBankName}
								/>
								<p>{t('pages.withdrawal.step2.label.bic')}</p>
								<Input
									onChange={(value) => handleBankDetailsChange('receiverBIC', value)}
									placeholder="Ex. BCNSSXXXX"
									type="text"
									value={bankDetails.receiverBIC}
								/>
								<p>{t('pages.withdrawal.step2.label.streetAndNumber')}</p>
								<Input
									onChange={(value) => handleBankDetailsChange('receiverStreetAndNumber', value)}
									placeholder="Ex. Lublin 123"
									type="text"
									value={bankDetails.receiverStreetAndNumber}
								/>
								<p>{t('pages.withdrawal.step2.label.city')}</p>
								<Input
									onChange={(value) => handleBankDetailsChange('receiverCity', value)}
									placeholder="Ex. New York City"
									type="text"
									value={bankDetails.receiverCity}
								/>
								<p>{t('pages.withdrawal.step2.label.postalCode')}</p>
								<Input
									onChange={(value) => handleBankDetailsChange('receiverPostalCode', value)}
									placeholder="Ex. 9948838"
									type="text"
									value={bankDetails.receiverPostalCode}
								/>
							</>
						)}
					</div>
					<div className="flex flex-col justify-center">
						<PageLogo />
						{errMsg && <ErrorBubble error={errMsg} />}
						{validationErrMsg && <ErrorBubble error={validationErrMsg} />}
						<PrimaryButton
							text={!isVerified && wireType !== 'INSTANT' ? t('buttons.verifyIban') : t('buttons.next')}
							className="mb-[10px]"
							textColor="text-white"
							onClick={async () => {
								if (!isVerified && wireType !== 'INSTANT') return await getDetailsByIban();
								const isValid = validate({ ...bankDetails, wireType });
								if (!isValid) return;

								setStep(step + 1);
							}}
						/>
					</div>
				</>
			)}

			{step === 2 && (
				<>
					<div dir={langDir}>
						<Header
							showLanguage={false}
							title={type === 'paytora' ? t('pages.withdrawal.header.paytora') : t('pages.withdrawal.header.iban')}
							backNavigation={
								step === 0
									? null
									: () => {
											setStep(step - 1);
											setAmount(0);
											setExchangedAmount(0);
											setReason('');
											setErrMsg('');
									  }
							}
						/>

						<AccountDetailsSubHeader />
						<p>{t('pages.withdrawal.step3.label.currency')}</p>
						<Input disabled={true} value={currency} />

						<p>{t('pages.withdrawal.step3.label.amount')} (€)</p>
						<Input
							placeholder="0.00"
							type="number"
							value={amount}
							onChange={(value) => {
								if (value.length > 15) return;
								setAmount(value);
								if (currency !== 'EUR') {
									getRates(value);
								} else {
									setExchangedAmount(value);
								}
							}}
							inputMode="numeric"
							pattern="[0-9]"
						/>
						{wireType === 'INSTANT' && <p className="text-sm"> {t('pages.withdrawal.step3.label.minimum.chargedAmount')}</p>}
						<p>{t('pages.withdrawal.step3.label.reason')}</p>
						<Input
							onChange={(value) => {
								setReason(value);
							}}
							placeholder="Ex. Food"
							type="text"
							value={reason}
						/>
						<div>
							<p>
								You will be charged:
								{' €' + (amount && amount > 0 ? numeral(Math.round(parseFloat(amount) + getAmountWithFees(amount))).format('0,0') : 0)}
							</p>
							<p>You will be receive: {amount && amount > 0 ? numeral(exchangedAmount).format('0,0') + ' ' + currency : 0}</p>
						</div>
					</div>
					<div className="flex flex-col justify-center">
						<PageLogo />
					</div>
					<div>
						{errMsg && <ErrorBubble error={errMsg} />}
						<PrimaryButton
							text={t('buttons.send')}
							className="mb-[10px]"
							iconPath="icons/arrow_out_white.svg"
							textColor="text-white"
							onClick={() => setIsPincodeModalOpen(true)}
						/>
					</div>
				</>
			)}

			<CustomModal
				isOpen={isSuccessModalOpen}
				onClose={() => setIsSuccessModalOpen(false)}
				title={t('modals.withdrawal.title')} //'Your withdraw action has been submitted'
				subTitle={t('modals.withdrawal.subTitle')} //You will receive a notification while process completed
				children={
					<div>
						<p className="pb-5 text-red font-bold">{t('modals.withdrawal.message')} </p>
						<div className="text-center">
							<PrimaryButton
								onClick={async () => {
									setIsSuccessModalOpen(false);
									window.location.reload();
								}}
								text={t('buttons.close')}
								textColor="text-white"
							/>
						</div>
					</div>
				}
			/>

			<PincodeModal
				isOpen={isPincodeModalOpen}
				onClose={() => {
					setIsPincodeModalOpen(false);
				}}
				onAction={async (pincode) => {
					await handleBankWire(pincode);
					setIsPincodeModalOpen(false);
				}}
			/>
		</div>
	);
}
