import { useTranslation } from 'react-i18next';

import { Header } from '../../components/navigation/header';
import SelectInput from '../../components/inputs/selectInput';
import { useEffect, useState } from 'react';
import { PrimaryButton } from '../../components/buttons/primary-button';
import { getFeeByType } from '../../services/fees';
import { exportTransactionsAPI } from '../../services/transactions';
import { ErrorBubble } from '../../components/utils/errorBubble';
import { CustomModal } from '../../components/modals/customModal';
import { SecondaryButton } from '../../components/buttons/secondary-button';
import Loader from '../utils/loader';

const options = [
	{
		label: 'This Month',
		value: 'this_month',
	},
	{
		label: 'Last Month',
		value: 'last_month',
	},
	{
		label: 'Last 3 Months',
		value: 'last_3_months',
	},
	{
		label: 'Last 6 Months',
		value: 'last_6_months',
	},
];

export default function EStatements() {
	const { t } = useTranslation();

	const [selectedTimeframe, setSelectedTimeframe] = useState(options[0].value);

	const [isLoading, setIsLoading] = useState(false);

	const [fee, setFee] = useState(0);

	const [error, setErr] = useState('');

	const [approveModalOpen, setApproveModalOpen] = useState(false);

	const handleEStateDownload = async () => {
		if (!isLoading) {
			setIsLoading(true);

			const response = await exportTransactionsAPI(selectedTimeframe);

			if (!response.isSuccess) {
				setErr(response.error ?? 'Something went wrong, please try again later or contact support');
			} else {
				// Trigger file download
				const blob = new Blob([response.data], {
					type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
				});
				const today = new Date();
				const url = window.URL.createObjectURL(blob);
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', 'transaction_report' + today + '.xlsx'); // Set the file name
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link); // Clean up
				setApproveModalOpen(false);
			}
		}
		setIsLoading(false);
	};

	const getFees = async (option) => {
		const response = await getFeeByType({ type: 'export_' + option });

		if (response.isSuccess) setFee(response.data[0].cost);
	};

	return isLoading ? (
		<Loader />
	) : (
		<div className="p-5 h-screen flex flex-col justify-between">
			<div>
				<Header title={t('pages.eStatements.header')} />
				<p className="text-md mt-2 mb-2">{t('pages.eStatements.text')}</p>
				<SelectInput
					options={options}
					defaultValue={{ value: options[0].value, label: options[0].label }}
					onChange={(e) => {
						setSelectedTimeframe(e);
						getFees(e);
					}}
				/>
				{fee > 0 && (
					<p className="mt-2 text-red">
						{t('pages.eStatements.subTitle.cost')}
						{fee}
					</p>
				)}
			</div>
			<div>
				{error && <ErrorBubble error={error} />}
				<PrimaryButton text={t('buttons.download')} onClick={() => setApproveModalOpen(true)} />
			</div>

			<CustomModal
				isOpen={approveModalOpen}
				onClose={() => setApproveModalOpen(false)}
				title={t('modals.eStatements.title')}
				subTitle={t('modals.eStatements.subTitle')}
				children={
					<div>
						{fee > 0 ? <p className="text-red">{t('pages.eStatements.subTitle.cost') + fee}</p> : <p>Free</p>}
						<div className="flex flex-col space-y-2  justify-center mt-[20px]">
							<PrimaryButton onClick={handleEStateDownload} text={t('buttons.download')} textColor="text-white" />
							<SecondaryButton
								onClick={() => {
									setApproveModalOpen(false);
								}}
								text={t('buttons.close')}
								textColor="text-black"
							/>
						</div>
						<p className="text-red text-center mt-2">{error}</p>
					</div>
				}
			/>
		</div>
	);
}
