import numeral from 'numeral';

import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { CustomModal } from '../modals/customModal';
import { AddressVerifyModal } from '../modals/addressVerifyModal';

import Loader from '../../pages/utils/loader';
import { createVerestroIbanAPI } from '../../services/ibans';

import { PrimaryButton } from '../buttons/primary-button';
import { SecondaryButton } from '../buttons/secondary-button';

import { getVerestroUserExists } from '../../services/user';
import { getUserKYCStatusAPI } from '../../services/kyc';

export const IbanBalance = ({ ibanBalance, hasIban }) => {
	const navigate = useNavigate();

	const { t } = useTranslation();

	const langDir = localStorage.getItem('languageDir');

	const [isLoading, setIsLoading] = useState(false);

	const [showVerifyAddressModal, setShowVerifyAddressModal] = useState(false);

	const [kycLevel, setKycLevel] = useState('none');

	const [isIbanModalOpen, setIsIbanModalOpen] = useState(false);
	const [isIbanSuccessOpen, setIsIbanSuccessOpen] = useState(false);
	const [isIbanFailOpen, setIsIbanFailOpen] = useState(false);

	const [agreeToTerms, setAgreeToTerms] = useState(false);

	const [errMsg, setErrMsg] = useState('');

	const createVerestroIBAN = async () => {
		if (!agreeToTerms) return setErrMsg(t('errors.validations.termsAndPrivacy'));

		if (hasIban) return navigate('/transactions/iban', { state: { balance: ibanBalance } });

		if (!isLoading) {
			setIsLoading(true);
			const response = await createVerestroIbanAPI();
			if (!response.isSuccess) {
				setIsLoading(false);
				if (response.error === 'NOT_ALLOWED_BY_COUNTRY') {
					setIsIbanModalOpen(false);
					setIsIbanFailOpen(true);
					return;
				}
				setErrMsg(response.error ?? t('errors.server.GENERAL_ERROR'));
				return;
			}
			localStorage.setItem('hasIban', true);
			setIsIbanSuccessOpen(true);
			setIsIbanModalOpen(false);
		}
		setIsLoading(false);
	};

	const applyForIBAN = async () => {
		if (!isLoading) {
			setIsLoading(true);
			const response = await getVerestroUserExists();
			if (!response.data) {
				setIsLoading(false);
				return setShowVerifyAddressModal(true);
			}
			setIsIbanModalOpen(true);
		}
		setIsLoading(false);
	};

	useEffect(() => {
		const fetchKycLevel = async () => {
			const response = await getUserKYCStatusAPI();
			if (response.isSuccess) setKycLevel(response.data);
		};

		fetchKycLevel();
	}, []);

	return (
		<div dir={langDir}>
			{!hasIban ? (
				<div
					className="flex justify-between bg-lightGray p-5 mt-[10px] rounded-[10px] w-full"
					onClick={() => {
						if (kycLevel !== 'completed') return navigate('/kyc');
						applyForIBAN();
					}}
				>
					<div>
						<p className="text-sm text-darkGray">{t('pages.dashboard.ibanBalance.noIban')}</p>
						<h1 className="font-bold text-xxl text-gradient">{t('pages.dashboard.ibanBalance.request')}</h1>
					</div>
					<img src="/assets/icons/right_arrow_black.svg" />
				</div>
			) : (
				<div
					className="flex justify-between bg-lightGray p-5 mt-[10px] rounded-[10px] w-full"
					onClick={() => navigate('/transactions/iban', { state: { balance: ibanBalance } })}
				>
					<div>
						<p className="text-sm text-darkGray">{t('pages.dashboard.ibanBalance.balance')}</p>
						<h1 className="text-xxl text-black">€{numeral(ibanBalance).format('0,0.00')}</h1>
					</div>
					<img src={`/assets/icons/right_arrow_black.svg`} />
				</div>
			)}

			<CustomModal
				isOpen={isIbanModalOpen}
				onClose={() => {
					setIsIbanModalOpen(false);
					setErrMsg('');
				}}
				title={t('modals.iban.apply.title')}
				subTitle={t('modals.iban.apply.subTitle')}
				children={
					isLoading ? (
						<Loader addtionalText={t('pages.loader.additionalText')} />
					) : (
						<div>
							<div className="text-sm flex">
								<input
									type="checkbox"
									id="termsCheckbox"
									checked={agreeToTerms}
									onChange={() => setAgreeToTerms(!agreeToTerms)}
									className="bg-darkGray"
								/>
								<label htmlFor="termsCheckbox" className="ms-2 text-darkGray">
									{t('termsAndPrivacy.title')}
									<span className="text-gradient cursor-pointer m-2" onClick={() => window.open('/iban/terms', '_blank')}>
										{t('termsAndPrivacy.terms')}
									</span>
								</label>
							</div>
							<div className="flex flex-col justify-center mt-[20px]">
								<PrimaryButton onClick={createVerestroIBAN} text={t('buttons.apply')} textColor="text-white" />
								<SecondaryButton
									onClick={() => {
										setErrMsg('');
										setIsIbanModalOpen(false);
									}}
									text={t('buttons.close')}
									textColor="text-black"
								/>
							</div>
							<p className="text-red text-center mt-2">{errMsg}</p>
						</div>
					)
				}
			/>

			<CustomModal
				isOpen={isIbanSuccessOpen}
				onClose={() => setIsIbanSuccessOpen(false)}
				title={t('modals.iban.success.title')}
				subTitle={t('modals.iban.success.subTitle')}
				children={
					<div className="flex justify-center">
						<PrimaryButton
							onClick={() => {
								setIsIbanSuccessOpen(false);
								window.location.reload();
							}}
							text={t('buttons.close')}
							textColor="text-white"
						/>
					</div>
				}
			/>

			<CustomModal
				isOpen={isIbanFailOpen}
				onClose={() => setIsIbanFailOpen(false)}
				title={t('modals.iban.failed.title')}
				subTitle={t('modals.iban.failed.subTitle')}
				children={
					<div className="flex flex-col justify-center">
						<p className="text-red mb-5">* {t('modals.iban.failed.message')}</p>
						<PrimaryButton
							onClick={() => {
								navigate('/profile/address/edit');
							}}
							text={t('buttons.updateAddress')}
							textColor="text-white"
						/>
						<SecondaryButton
							onClick={() => {
								setIsIbanFailOpen(false);
							}}
							text={t('buttons.close')}
							textColor="text-black"
						/>
					</div>
				}
			/>

			<AddressVerifyModal
				isOpen={showVerifyAddressModal}
				onClose={() => setShowVerifyAddressModal(false)}
				onAction={() => setIsIbanModalOpen(true)}
			/>
		</div>
	);
};
