import { useEffect, useState } from 'react';

import numeral from 'numeral';

import { getDecodedToken } from '../../utils/functions/token';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUserAccounts } from '../../store/accountsSlice';

export const AccountDetailsSubHeader = ({ showBalance = true, className, showAmount = false, amount = 0 }) => {
	const { t } = useTranslation();

	const dispatch = useDispatch();
	const { totalBalance } = useSelector((state) => state.accounts);

	const langDir = localStorage.getItem('languageDir');

	const [accountNumber, setAccountNumber] = useState('-');

	useEffect(() => {
		if (showBalance) {
			dispatch(fetchUserAccounts());
		}
	}, [dispatch]);

	useEffect(() => {
		const decodedToken = getDecodedToken();
		setAccountNumber(decodedToken?.customerNumber ?? '');
	}, []);

	return (
		<div className={`flex justify-between mb-[40px] ${className}`} dir={langDir}>
			<div>
				<p className="text-sm">{t('pages.dashboard.accountDetails.paytoraCustomer')}</p>
				<p className="text-lg">{accountNumber}</p>
			</div>
			{showBalance && (
				<div>
					<p className="text-sm">{t('pages.dashboard.accountDetails.totalBalance')}</p>
					<p className="text-lg text-end">€{numeral(totalBalance).format('0,0.00')}</p>
				</div>
			)}
			{showAmount && (
				<div>
					<p className="text-sm">{t('pages.dashboard.accountDetails.receivedAmount')}</p>
					<p className="text-lg text-end text-red">{amount > 0 ? '€' + amount : ''}</p>
				</div>
			)}
		</div>
	);
};
