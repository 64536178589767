import { getStorageToken } from '../utils/functions/token';
import { apiRequest } from './api';

export async function transferToUserAPI(data) {
	try {
		await apiRequest('/user/transfer/user', 'POST', data);
		return { isSuccess: true };
	} catch (error) {
		return { isSuccess: false, error: error.message };
	}
}

export async function getTransferReceiverByCustomerNumberAPI(customerNumber) {
	try {
		const response = await apiRequest('/user/transfer/receiver/' + customerNumber, 'GET');

		return { isSuccess: true, data: response.data };
	} catch (error) {
		return { isSuccess: false, error: error.message };
	}
}

export async function otherTopUpAccountAPI(data) {
	try {
		const response = await apiRequest('/user/deposit/paytora/other', 'POST', data);
		return { isSuccess: true, data: response.data };
	} catch (error) {
		return { isSuccess: false, error: error.message };
	}
}

export async function cardTopUpAccountAPI(data) {
	try {
		const response = await apiRequest('/user/deposit/all', 'POST', data);
		return { isSuccess: true, data: response.data };
	} catch (error) {
		return { isSuccess: false, error: error.message };
	}
}

export const exportTransactionsAPI = async (timeframe) => {
	const API_URL = process.env.REACT_APP_API_URL;

	try {
		const response = await fetch(`${API_URL}/user/transactions/export`, {
			method: 'POST',
			headers: {
				Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
				Authorization: 'Bearer ' + getStorageToken(),
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({ timeframe }),
		});

		if (!response.ok) {
			const error = await response.json();
			return { isSuccess: false, error: error.error || 'Failed to export transactions' };
		}

		const data = await response.blob(); // Get binary data
		return { isSuccess: true, data };
	} catch (err) {
		return { isSuccess: false, error: err.message };
	}
};
