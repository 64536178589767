import { useEffect, useState } from 'react';
import { getUserIbanStatus } from '../services/ibans';

export const useIbanStatus = () => {
	const [isLoading, setIsLoading] = useState(false);
	const [hasIban, setHasIban] = useState(false);
	const [errMsg, setErrMsg] = useState('');

	const fetchIbanStatus = async () => {
		if (!isLoading) {
			setIsLoading(true);
			try {
				const response = await getUserIbanStatus();

				if (response.isSuccess) {
					setHasIban(response.data);
				} else {
					setErrMsg(response.error ?? 'An error occurred.');
				}
			} catch (error) {
				setErrMsg('An unexpected error occurred.');
			} finally {
				setIsLoading(false);
			}
		}
	};

	useEffect(() => {
		fetchIbanStatus();
	}, []); // Fetch on component mount

	return { isLoading, hasIban, errMsg, refetch: fetchIbanStatus };
};
