import numeral from 'numeral';

import { useEffect, useState } from 'react';
import { decodeToken } from 'react-jwt';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import Loader from '../utils/loader';

import { getVerestroIbanAPI } from '../../services/ibans';
import { getUserTransactionsAPI } from '../../services/user';

import { Header } from '../../components/navigation/header';
import { InstructionBubble } from '../../components/utils/InstructionBubble';
import { AccountDetailsSubHeader } from '../../components/navigation/accountDetailsSubHeader';
import { GradientButton } from '../../components/buttons/gradient_button';
import { TransactionsContainer } from '../../components/transactions/transactionsContainer';
import { PrimaryButton } from '../../components/buttons/primary-button';
import { CustomModal } from '../../components/modals/customModal';

export default function IbanTransactions() {
	const { t } = useTranslation();
	const langDir = localStorage.getItem('languageDir');

	const navigate = useNavigate();

	const location = useLocation();

	const ibanBalance = location.state?.balance ?? 0;

	const token = localStorage.getItem('token');
	const decodedToken = decodeToken(token);
	const fullname = decodedToken.fullName;

	const [isLoading, setIsLoading] = useState(false);

	const [ibanData, setIbanData] = useState(null);

	const [transactions, setTransactions] = useState([]);

	const [showContriesListModal, setShowCountriesListModal] = useState(false);

	useEffect(() => {
		const getVerestroIBANDetails = async () => {
			setIsLoading(true);
			const response = await getVerestroIbanAPI();
			if (!response.isSuccess) {
				setIsLoading(false);
				return;
			}
			setIbanData(response.data);

			setIsLoading(false);
		};

		const fetchTransactions = async () => {
			const transactions = await getUserTransactionsAPI('iban');

			if (transactions.isSuccess) setTransactions(transactions.data);
		};

		fetchTransactions();
		getVerestroIBANDetails();
	}, []);

	return isLoading ? (
		<Loader />
	) : (
		<div className={`p-5 bg-lighterGray ${!ibanData && 'h-screen'}`} dir={langDir}>
			<Header title={t('pages.ibanTransactions.header')} />
			<AccountDetailsSubHeader />
			{!ibanData ? (
				<p className="text-center mt-5">{t('pages.ibanDetails.label.noIban')}</p>
			) : (
				<div>
					<InstructionBubble
						title={t('pages.ibanDetails.instructions.title')}
						text={
							<div>
								<h1 className="text-md text-center font-bold mb-1">{t('pages.ibanDetails.instructions.text')}</h1>
								<ul className="text-start">
									<li>{t('pages.ibanDetails.instructions.option1')}</li>
									<li>{t('pages.ibanDetails.instructions.option2')}</li>
									<li>{t('pages.ibanDetails.instructions.option3')}</li>
								</ul>
							</div>
						}
					/>

					<p className="text-center text-lg mt-2">
						{t('pages.ibanDetails.label.restricted.country')}
						<PrimaryButton text={t('buttons.viewCountries')} className="font-bolder ms-1" onClick={() => setShowCountriesListModal(true)} />
					</p>

					<div className="flex justify-between bg-lightGray p-5 mt-[10px] rounded-[10px] w-full">
						<div>
							<p className="text-sm text-darkGray">{t('pages.ibanTransactions.balance.title')}</p>
							<h1 className="text-xxl text-black">€{numeral(ibanBalance).format('0,0.00')}</h1>
						</div>
					</div>

					<div className="flex justify-between mt-[20px]">
						<GradientButton
							text={t('buttons.send')}
							className="me-2"
							iconPath="icons/arrow_out.svg"
							onClick={() => navigate('/send', { state: { isIban: true } })}
						/>
						<GradientButton
							text={t('buttons.deposit')}
							iconPath="icons/arrow_in.svg"
							onClick={() => navigate('/deposit', { state: { isIban: true } })}
						/>
					</div>

					<div>
						<h1 className="text-lg font-bolder mt-[40px]">{t('pages.ibanTransactions.details.title')}</h1>
						<p className="text-sm text-darkGray mt-[20px]">{t('pages.ibanDetails.details.holderName')}</p>
						<p className="mb-[20px]">{fullname}</p>
						<p className="text-sm text-darkGray">{t('pages.ibanDetails.details.iban')}</p>
						<p className="mb-[20px]">{ibanData ? ibanData.iban : ''}</p>
						<p className="text-sm text-darkGray">{t('pages.ibanDetails.details.bic')}</p>
						<p className="mb-[20px]">{ibanData ? ibanData.bic : ''}</p>
						<p className="text-sm text-darkGray">{t('pages.ibanDetails.details.bankName')}</p>
						<p className="mb-[20px]">Bank Polska Kasa Opieki Spolka Akcyjna</p>
						<p className="text-sm text-darkGray">{t('pages.ibanDetails.details.address')}</p>
						<p className="mb-[20px]">al. Wojska Polskiego 1</p>
						<p className="text-sm text-darkGray">{t('pages.ibanDetails.details.city')}</p>
						<p className="mb-[20px]">Szczecin</p>
						<p className="text-sm text-darkGray">{t('pages.ibanDetails.details.postalCode')}</p>
						<p className="mb-[20px]">70-470</p>
						<p className="text-sm text-darkGray">{t('pages.ibanDetails.details.country')}</p>
						<p className="mb-[20px]">Poland (PL)</p>
					</div>

					<TransactionsContainer transactions={transactions} />
				</div>
			)}

			<CustomModal
				isOpen={showContriesListModal}
				onClose={() => setShowCountriesListModal(false)}
				title={t('modals.ibanDetails.title')}
				subTitle={t('modals.ibanDetails.subTitle')}
				children={
					<div>
						<p className="text-red font-bold text-center">{t('modals.ibanDetails.message')}</p>
						<ul className="p-2 pb-5 font-bold" dir="ltr">
							<li>Afghanistan</li>
							<li>Barbados</li>
							<li>Burkina Faso</li>
							<li>Democratic Republic of the Congo</li>
							<li>Philippines</li>
							<li>Gibraltar</li>
							<li>Haiti</li>
							<li>Iran</li>
							<li>Jamaica</li>
							<li>Yemen</li>
							<li>Cameroon</li>
							<li>Democratic People's Republic of Korea</li>
							<li>Mali</li>
							<li>Myanmar/Burma</li>
							<li>Mozambique</li>
							<li>Nigeria</li>
							<li>Panama</li>
							<li>South Africa</li>
							<li>Senegal</li>
							<li>South Sudan</li>
							<li>Syria</li>
							<li>Tanzania</li>
							<li>Trinidad and Tobago</li>
							<li>Uganda</li>
							<li>Vanuatu</li>
							<li>Vietnam</li>
							<li>United Arab Emirates</li>
						</ul>
						<div className="text-center">
							<PrimaryButton onClick={async () => setShowCountriesListModal(false)} text={t('buttons.close')} textColor="text-white" />
						</div>
					</div>
				}
			/>
		</div>
	);
}
