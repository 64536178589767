import React from 'react';

import Modal from 'react-modal';

export const CustomModal = ({ isOpen, onClose, title, subTitle, children, height = 'auto' }) => {
	const langDir = localStorage.getItem('languageDir');

	return (
		<Modal
			isOpen={isOpen}
			onRequestClose={onClose}
			shouldCloseOnOverlayClick={false}
			ariaHideApp={false}
			style={{
				overlay: {
					backgroundColor: 'rgba(0, 0, 0, 0.5)',
					zIndex: 1000,
				},
				content: {
					position: 'fixed',
					bottom: '0',
					top: 'auto', // Ensure it's anchored from the bottom
					left: '50%',
					transform: 'translateX(-50%)',
					width: '100%',
					maxWidth: '500px',
					height, // Set height to 40%
					maxHeight: '90vh',
					// borderRadius: '12px 12px 0 0',
					padding: '20px',
					margin: '0',
					overflow: 'auto',
					WebkitOverflowScrolling: 'touch',
					boxSizing: 'border-box',
					// zIndex: 1000,
				},
			}}
		>
			<div dir={langDir}>
				<h2 className="text-xl font-bold mt-[20px]">{title}</h2>
				<p className="text-md text-darkGray mb-[20px]">{subTitle}</p>
				{children}
			</div>
		</Modal>
	);
};
